import axios from 'axios'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { Notification} from 'element-ui'
import config from '@/config/index'



axios.defaults.headers['Content-Type'] = 'application/json'
// 创建axios实例
const service = axios.create({
  baseURL:config.baseurl,
// 超时
  timeout: 20000
})
// request拦截器
service.interceptors.request.use(config => {

  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && getToken() != 'undefined' && !isToken) {
    config.headers['token'] =getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // console.log(res,'configconfigconfig')
    // 未设置状态码则默认成功状态
    const code = res.data.code || 20000;
    if(res.status != 200){
      return Promise.reject('error')
    }
    // 获取错误信息
    const msg = res.data.message || errorCode[code] || errorCode['default']
   if (code !== 20000 && code !== 30000) {
     if(!judgeUrl(res.config.url)){
       Notification.error({
         title: msg || '操作错误'
       })
     }

    }
    return res.data
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }

    return Promise.reject(error)
  }
)
function judgeUrl(url){
  const surls = ['abcode/course/queryUserIsPayCourseAndPass','abcode/order/createOrder','abcode/course/getPlayAuth/','abcode/order/queryUserIsPayThisCourse','abcode/pay/queryOrderPayStatus','abcode/user/queryUserInfo']
  let flag = false
  surls.forEach(item=>{
    if(url.indexOf(item)>-1){
      flag=true
    }
  })
  return flag
}
export default service
