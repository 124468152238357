<template>
  <div>
    <!-- 盲盒首页 -->
    <div class="wly_mh_th_box" v-if="isGuans">
      <div class="wly_mh_th">
        <img src="@/assets/blindBox/lihe-shang.png" class="lihe_shang" />
        <img src="@/assets/blindBox/lihe-xia.png" class="lihe_xia" />
        <div class="wly_close_box" @click="getGuan,$emit('close',true)">
          <img src="@/assets/blindBox/close.png" />
        </div>
        <div class="wly_mh_kkk">
          <img src="@/assets/blindBox/mh_kkk.png" />
        </div>
        <div class="wly_mh_list">
          <div class="wly_mh_item" @mouseenter="mouseEnter(index)" v-for="(item,index) in chaijiang" :key="index"
               @click="getChai(index)" :class="item.flag==index?'jz':''">
            <div class="wly_jl" v-if="item.flag==index">
              {{ item.prizeName }}
            </div>
            <img src="@/assets/blindBox/manghechai.png" class="wly_manghechai" v-if="item.flag==index" />
            <img src="@/assets/blindBox/chai.png" class="wly_chai" v-if="item.flag!=index" />
            <img src="@/assets/blindBox/wly_lw.png" class="wly_lw" v-if="item.flag!=index" id="wly_lws" />
          </div>
        </div>
        <div class="wly_count">
          <div class="wly_count_box" v-if="judgeLogin()">
            <div class="wly_count_box_count">{{ sykqcs }}</div>
            <img src="@/assets/blindBox/shengyukaiqicishu.png" class="wly_sycs" />
          </div>
          <div class="wly_buttom">
            <img src="@/assets/blindBox/huoqukaiqicishu.png" class="wly_left_1" @click="getCount" />
            <img src="@/assets/blindBox/wly_left_2.png" class="wly_left_2" @click="getHost" />
          </div>
        </div>
      </div>
    </div>

    <!-- 拆盲盒 -->
    <div class="wly_chai_boxxx" v-if="isKai">
      <div class="wly_chai_box">
        <div class="wly_chai_box_img">
          <div class="caidai_a c_one_1"><img src="@/assets/blindBox/caidai1.png" /></div>
          <div class="caidai_a c_one_2"><img src="@/assets/blindBox/caidai2.png" /></div>
          <div class="caidai_a c_one_3"><img src="@/assets/blindBox/caidai3.png" /></div>
          <div class="caidai_a c_one_4"><img src="@/assets/blindBox/caidai4.png" /></div>
          <div class="caidai_a c_one_5"><img src="@/assets/blindBox/caidai5.png" /></div>
          <div class="caidai_a c_one_6"><img src="@/assets/blindBox/caidai6.png" /></div>
          <div class="caidai_a c_one_7"><img src="@/assets/blindBox/caidai7.png" /></div>
          <div class="caidai_a c_one_8"><img src="@/assets/blindBox/caidai8.png" /></div>
          <div class="caidai_a c_one_9"><img src="@/assets/blindBox/caidai9.png" /></div>
          <div class="caidai_a c_one_10"><img src="@/assets/blindBox/caidai10.png" /></div>
          <div class="caidai_a c_one_11"><img src="@/assets/blindBox/caidai11.png" /></div>
          <div class="caidai_a c_one_12"><img src="@/assets/blindBox/caidai12.png" /></div>
          <div class="caidai_a c_one_13"><img src="@/assets/blindBox/caidai13.png" /></div>
          <div class="caidai_a c_one_14"><img src="@/assets/blindBox/caidai14.png" /></div>
          <div class="caidai_a c_one_15"><img src="@/assets/blindBox/caidai15.png" /></div>
          <div class="caidai_a c_one_24"><img src="@/assets/blindBox/caidai15.png" /></div>
          <div class="caidai_a c_one_16"><img src="@/assets/blindBox/caidai16.png" /></div>
          <div class="caidai_a c_one_17"><img src="@/assets/blindBox/caidai17.png" /></div>
          <div class="caidai_a c_one_18"><img src="@/assets/blindBox/caidai18.png" /></div>
          <div class="caidai_a c_one_19"><img src="@/assets/blindBox/caidai19.png" /></div>
          <div class="caidai_a c_one_20"><img src="@/assets/blindBox/caidai20.png" /></div>
          <div class="caidai_a c_one_21"><img src="@/assets/blindBox/caidai21.png" /></div>
          <div class="caidai_a c_one_22"><img src="@/assets/blindBox/caidai22.png" /></div>
          <div class="caidai_a c_one_23"><img src="@/assets/blindBox/caidai23.png" /></div>
          <div class="cai_a"><img src="@/assets/blindBox/caidai.png" /></div>
          <div class="cai_b"><img src="@/assets/blindBox/caidai.png" /></div>
          <div class="cai_c"><img src="@/assets/blindBox/caidai.png" /></div>
          <div class="gongxinihuhode"><img src="@/assets/blindBox/gongxinihuhode.png" /></div>
          <div><img src="@/assets/blindBox/manghechai.png" class="manghechai" /></div>
          <div class="yh_text">
            <img :src="jp.prizeImage" class="jpImg" alt="奖品图片未加载出来" />
          </div>
          <img src="@/assets/blindBox/shouxiaanniu.png" class="shouxiaanniu wly_avt1" @click="getSx"/>
        </div>

        </div>
    </div>

    <!-- 获取开启次数 -->
    <div class="wly_counts_box" v-if="isGetCount">
      <div class="wly_counts">
        <div class="wly_counts_boxxx">
          <div class="wly_counts_text">
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a">1. 注册送2次</div>
            </div>
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a">2. 邀请新用户注册，双方各送2次</div>
            </div>
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a">3. 扫右侧二维码加入技术群联系管理员送1次</div>
            </div>
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a">4. 购买课程送3次</div>
              <div class="goview"  @click="goUrl(1)">去浏览</div>
            </div>
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a" >5. 报名最新活动送1次</div>
              <div class="goview" @click="goUrl(3)">去报名</div>
            </div>
            <div class="wly_counts_text_item">
              <div class="wly_counts_text_item_left_a">6. 单次随机刷题≥15道且正确率≥90%送1次</div>
              <div class="goview"  @click="goUrl(2)">去刷题</div>
            </div>
            <div class="wly_counts_text_item_buttom">
              <div>* 本活动最终解释权归ABCode所有</div>
              <div>* 若使用作弊手段将取消获奖资格</div>
            </div>
          </div>
        </div>
        <img src="@/assets/blindBox/close.png" class="wly_close" @click="getIsCount(1)" />
        <img src="@/assets/blindBox/huoqubeijing.png" />
      </div>
    </div>

    <!-- 查看开启记录 -->
    <div class="wly_counts_box" v-if="isGetHost">
      <div class="wly_counts">
        <div class="wly_counts_boxx">
          <div class="wly_counts_text">
            <div class="wly_counts_text_item" v-for="(item,index) in kqjl" :key="index">
              <div class="wly_counts_text_item_left">
                {{ index + 1 }}.&nbsp;{{ item.prizeName }}
              </div>
              <div class="wly_counts_text_item_time">
                {{ item.createTime }}
              </div>
              <div class="goview"  @click="goUrl(4)" v-if="judgeCoupon(item)">去查看</div>
              <div class="goRedeem "  @click="popMsg()" v-else-if="item.isGrant == 0">去兑换</div>
              <div class="redeemed defaultpoint"  v-else-if="item.isGrant == 1">已兑换</div>
<!--              <div class="wly_counts_text_item_right" v-else>-->
<!--                {{  ? '已兑换':'未兑换' }}-->
<!--              </div>-->
            </div>
            <div class="wly_counts_text_item_buttom">
              <div>* 平台以外奖品，扫右侧二维码加入技术群联系管理员获取</div>
            </div>
          </div>
        </div>
        <img src="@/assets/blindBox/close.png" class="wly_close" @click="getIsCount(2)" />
        <img src="@/assets/blindBox/jilubeijing.png" />
      </div>
    </div>

  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { getRequest } from "@/utils/common.js";
import { postRequest } from "@/utils/common.js";

export default {
  name: "blindBox",
  data() {
    return {
      isSx:false,
      isGetCount: false,
      isGetHost: false,
      isChai: true,
      isKai: false,
      isGuans: false,
      kqjl: [], // 开启记录
      sykqcs: "",// 剩余开启次数
      jp: {},//拆开,
      jsCount: "1000",
      chaijiang: [{
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }, {
        prizeName: "",
        prizeImage: "",
        flag: 9
      }],
      timer: "",
      types:'1'
    };
  },
  mounted() {

  },
  created() {
    this.getCj();
    this.getSykqcs();
  },
  methods: {
    popMsg(){
      this.$message.success("请扫描右侧二维码加入技术群，联系管理员兑换奖品~");
    },
    // 判断奖品是否为优惠券
    judgeCoupon(item){
      return item.prizeName.indexOf('优惠券') != -1 || item.prizeName.indexOf('码力') != -1;
    },
    goUrl(type){
      if(type == 1){
        const num = Math.round(Math.random() * 2);
        if(num == 0){
          window.open('http://www.abcodeedu.com/systemCourse');
        }else{
          window.open('http://www.abcodeedu.com/actualCombat/'+num);
        }
      }else if (type == 2){
        window.open('http://www.abcodeedu.com/brushQuestions');
      }else if (type == 3){
        window.open('http://www.abcodeedu.com/activitys');
      }else if (type == 4){
        window.open('http://www.abcodeedu.com/info');
      }
    },

    judgeLogin(){
      if (!getToken()) {
        return false;
      }else{
        return true;
      }
    },

    // 获取开启次数
    getCount() {
      this.isGetCount = true;
    },

    // 查看开启记录
    async getHost() {
      if (!getToken()) {
        this.$store.commit("setShowLogin", true);
        this.$message.warning("查看之前请先进行登录操作");
        return;
      }
      var that = this;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(function() {
        loading.close();
      }, 5000);
      let { code, data } = await getRequest("/abcode/user/unneed/queryUserPrizeList", {});
      loading.close();
      if (code == 20000) {
        // 获取开启记录
        that.kqjl = data.items;
        this.isGetHost = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning"
        });
      }
    },
    getIsCount(type) {
      if (type == 1) {
        this.isGetCount = false;
      } else if(type==2){
        this.isGetHost = false;
      } else if(type==3){
        this.isKai = false;
        this.isSx = false;
      }
    },

    // 拆盲盒
    async getChai(type) {
      if (!getToken()) {
        this.$store.commit("setShowLogin", true);
        this.$message.warning("开启之前请先进行登录操作");
        return;
      }
      var that = this;
      let { code, data } = await postRequest("/abcode/user/unneed/openPrize", {});
      if (code == 20000) {
        that.isKai = true;
        that.jsCount = type;
        clearInterval(that.timer);
        that.sykqcs = data.items.prizeNum;
        that.jp = data.items;
        that.types = type;
        // that.chaijiang[type] = data.items;
        // that.chaijiang[type].flag = type;
      } else {
        //错误提示
        this.$message({
          message: "您的开启盲盒次数不足，请查看“获取开启次数”",
          type: "warning"
        });
      }
    },
    // 开心收下
    getSx(){
      this.isKai = false;
      var tps = this.types;
      this.chaijiang[tps] = this.jp;
      this.chaijiang[tps].flag = tps;
    },
    getGuan() {
      this.isGuans = false;

      for (var i = 0; i < this.chaijiang.length; i++) {
        this.chaijiang[i].flag = "99";
      }
      this.jsCount = "o";
    },

    // 抽奖初始化
    getCj() {
      this.isGuans = true;
      this.timer = setInterval(this.addAvt, 4000);
    },

    // 随机给盲盒添加抖动动画
    addAvt() {
      let boxCollection = document.getElementsByClassName("wly_lw");

      const num1 = Math.round(Math.random() * 3);
      boxCollection[num1].classList.add("wly_avt");
      let num2 = Math.round(Math.random() * 3 + 4);
      boxCollection[num2].classList.add("wly_avt");

      setTimeout(function() {
        boxCollection[num1].classList.remove("wly_avt");
        boxCollection[num2].classList.remove("wly_avt");
      }, 3000);

    },

    // 鼠标移入清除动画
    mouseEnter(type) {
      if (this.jsCount == 1000) {
        let boxCollection = document.getElementsByClassName("wly_lw");
        boxCollection[type].classList.remove("wly_avt");
      }
    },

    // 获取剩余开启次数
    async getSykqcs() {
      if (!getToken()) {
        return;
      }
      var that = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(function() {
        loading.close();
      }, 5000);
      let { code, data } = await postRequest("/abcode/user/unneed/queryUserPrizeNum", {});
      loading.close();
      if (code == 20000) {
        // 获取次数
        that.sykqcs = data.items;
      } else {
        //错误提示
        this.$message({
        message:data.message,
          type: 'warning'
      });
      }
    }
  },
  beforeDestroy() {
    //清除定时器
    clearInterval(this.timer);
  }

};
</script>

<style scoped>
.wly_btn{
  width:100px;
  height:40px;
  background:#ffffff;
  position: absolute;
  top:50%;
  right: 10px;
  transform: translateY(-50%);
  box-shadow: 1px 1px 4px 3px #f8f2f2;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.wly_mh_th {
  width: 8rem;
  position: absolute;
  top: 53%;
  left: 50%;
  z-index: 99;
  background: url('../assets/blindBox/hengxiangbeijing.png') no-repeat center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
}

.wly_mh_th_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 101;
}

.wly_mh_kkk {
  width: 304px;
  position: absolute;
  top: -52px;
  left: 2.8rem;
}

.wly_mh_kkk img {
  width: 100%;
}

.wly_mh_list {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  width: calc(100% - 80px);
  padding: 40px 40px 0;
}

.wly_mh_item {
  width: calc(25% - 20px);
  height: 156px;
  border: 5px solid #ffedb4;
  margin-bottom: 20px;
  border-radius: 9px;
  position: relative;
  cursor: pointer;
}

.wly_lw {
  width: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .6s ease-out 0s;
  -webkit-transition: all .6s ease-out 0s;
  -moz-transition: all .6s ease-out 0s;
  -ms-transition: all .6s ease-out 0s;
  -o-transition: all .6s ease-out 0s;
}

.wly_chai {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  z-index: 2;
  transition: all .6s ease-out 0s;
  -webkit-transition: all .6s ease-out 0s;
  -moz-transition: all .6s ease-out 0s;
  -ms-transition: all .6s ease-out 0s;
  -o-transition: all .6s ease-out 0s;
  opacity: 0;
}

.wly_manghechai {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 199px;
  z-index: 3;
  animation: fadeIn 2s;
}

.wly_count {
  padding-bottom: 47px;
  padding-left: 86px;
  margin-top: 17px;
}

.wly_buttom {
  margin-top: 19px;
}

.wly_count_box {
  padding-left: 24px;
  position: relative;
}

.wly_count_box_count {
  position: absolute;
  top: 35%;
  left: 200px;
  transform: translateY(-50%);
  font-size: 36px;
  /*color:#ffddb5;*/
  background: linear-gradient(to top, #ffdeb5, #ffe4c3);
  -webkit-background-clip: text;
  color: transparent;
  font-family: '方正粗黑宋简体';
  font-weight: bold;
  width: 0.9rem;
  text-align: center;
}

.wly_mh_item:hover .wly_lw {
  transform: translate(-50%, -50%) scale(1.3);
}

.wly_mh_item:hover:hover .wly_chai {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.3);
}

/*.wly_mh_item:hover:hover .wly_avt {*/
/*  animation-play-state: paused !important;*/
/*}*/

.wly_counts {
  width: 5rem;
  /*height: 89%;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99999;
}

.wly_counts img {
  width: 100%;
  height: 100%;
}

.wly_counts_box {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.wly_close {
  position: absolute;
  top: -1px;
  right: -57px;
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.wly_counts_text {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
}

.wly_counts_text_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
}

.wly_counts_text_item_buttom{
  position: absolute;
  bottom: 20px;
  color: #FFEEA6;
}
.goRedeem{
  width: 0.7rem;
  padding: 3px 5px;
  text-align: center;
  border-radius: 20px;
  background-image: linear-gradient(#89f7fe, #66a6ff);
  font-weight: 600;
  color: #496ea5;
  font-size: 14px;
  cursor: pointer;
}
.goview {
  width: 0.7rem;
  padding: 3px 5px;
  text-align: center;
  border-radius: 20px;
  /*background: #ffb68e;*/
  background-image: linear-gradient(#FFEEA6, #FFC546);
  font-weight: 600;
  color: #ff5e40;
  font-size: 14px;
  cursor: pointer;
}
.redeemed{
  width: 0.7rem;
  padding: 3px 5px;
  text-align: center;
  border-radius: 20px;
  background-image: linear-gradient(#e7e4db, #a4a4a3);
  font-weight: 600;
  color: #737272;
  font-size: 14px;
  cursor: default;
}

.wly_buttom img {
  cursor: pointer;
}

.wly_jl {
  position: absolute;
  display: inline-block;
  z-index: 10;
  transform: rotate(12deg) translate(15px, -20px);
  color: #ffdeb5;
  /*font-weight: bold;*/
  font-family: '方正粗黑宋简体';
  text-shadow: 2px 2px 5px #7a7a7a7d;
  text-align: center;
  animation: fadeIn 2s;
}

.wly_chai_boxxx {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11111111111;
}

.wly_chai_box {
  width: 53%;
  height: 87%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wly_chai_box_img {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wly_chai_box_img .manghechai {
  width: 72%;
  display: block;
  margin: 0 auto;
  animation: fadeIn 2s;
}

.shouxiaanniu {
  width: 176px !important;
  display: block;
  margin: 40px auto 0;
  cursor: pointer;
}
.yh_text img{
  border-radius: 0.2rem;
}
.gongxinihuhode {
  width: 330px;
  display: block;
  margin: 0 auto 150px;
}

.wly_close_box {
  position: absolute;
  width: 25px;
  top: -28px;
  right: -40px;
  cursor: pointer;
}

.wly_close_box img {
  width: 100%;
}

.lihe_shang {
  width: 168px;
  position: absolute;
  top: -101px;
  animation: fadeInLeft 2s;
}

.lihe_xia {
  width: 280px;
  position: absolute;
  right: -70px;
  bottom: -64px;
  animation: fadeInRight 2s;
}

.wly_counts_text_item_right {
  /*font-size: 14px;*/
  width: 0.5rem;
  color: #ffbe9d;
}

.wly_sycs {
  width: 280px;
}

.wly_buttom img {
  width: 150px;
}

.wly_left_1 {
  margin-right: 26px;
}

.wly_counts_boxx {
  position: absolute;
  width: 4.25rem;
  height: 6rem;
  padding-top: 1.2rem;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  overflow-y: scroll;
}
.wly_counts_boxxx {
  position: absolute;
  width: 4.25rem;
  height: 6rem;
  padding-top: 1.2rem;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  overflow-y: scroll;
}
/* .box为当前出现滚动条的容器 */
.wly_counts_text::-webkit-scrollbar {
  display: none
}

.wly_counts_boxx::-webkit-scrollbar {
  display: none
}

.yh_text {
  font-size: 38px;
  position: absolute;
  top: 115px;
  right: 105px;
  transform: rotate(13deg);
  color: #ffe8cb;
  font-weight: bold;
  width: 160px;
  text-align: center;
}

.cai_a {
  width: 178px;
  position: absolute;
  left: -56px;
  top: 100px;
}

.cai_b {
  position: absolute;
  left: -40px;
  top: 180px;
  width: 115px;
  transform: rotate(
    149deg);
}

.cai_c {
  position: absolute;
  right: -40px;
  top: 250px;
  width: 115px;
  transform: rotate(
    -30deg);
}

.caidai_a {
  position: absolute;
}

.c_one_1 {
  right: 0;
  width: 19px;
}

.c_one_2 {
  right: 0;
  top: 32px;
  width: 13px;
}

.c_one_3 {
  top: -50px;
  left: 45px;
  width: 27px;
}

.c_one_4 {
  right: 0;
  top: 200px;
}

.c_one_5 {
  left: 1px;
  bottom: 274px;
  width: 56px;
  transform: rotate(
    12deg);
}

.c_one_6 {
  right: 231px;
  bottom: 270px;
  width: 24px;
}

.c_one_7 {
  right: 101px;
  bottom: 339px;
  width: 33px;
}

.c_one_8 {
  right: 76px;
  bottom: 192px;
  width: 33px;
}

.c_one_9 {
  right: 7px;
  bottom: 244px;
  width: 37px;
}

.c_one_10 {
  right: -52px;
  bottom: 244px;
  width: 27px;
}

.c_one_11 {
  bottom: 249px;
  right: 48px;
  width: 30px;
}

.c_one_12 {
  right: 62px;
  bottom: 185px;
  width: 29px;
}

.c_one_13 {
  left: -49px;
  bottom: 319px;
  width: 10px;
}

.c_one_14 {
  left: 55px;
  bottom: 240px;
  width: 20px;
  z-index: 10;
}

.c_one_15 {
  left: 231px;
  bottom: 187px;
  width: 47px;
  z-index: 11;
  transform: rotate(280deg);
}

.c_one_16 {
  left: 300px;
  bottom: 290px;
  width: 10px;
}

.c_one_17 {
  left: 96px;
  bottom: 236px;
  width: 6px;
}

.c_one_18 {
  left: 79px;
  bottom: 352px;
  width: 8px;
}

.c_one_19 {
  left: -27px;
  top: -41px;
  width: 17px;
}

.c_one_20 {
  left: 107px;
  top: 67px;
  width: 14px;
}

.c_one_21 {
  left: 382px;
  bottom: 331px;
  width: 9px;
}

.c_one_22 {
  left: 41px;
  bottom: 172px;
  width: 10px;
}

.c_one_23 {
  left: 278px;
  bottom: 300px;
}

.jpImg {
  width: 160px;
  font-size: 16px;
  animation: fadeInUp 1s;
}

.c_one_24 {
  left: 104px;
  transform: rotate(-24deg);
  z-index: 11;
  width: 52px;
  bottom: 185px;
}

.wly_lw {

}

.wly_avt {
  -webkit-animation-name: shake-rotate;
  -ms-animation-name: shake-rotate;
  animation-name: shake-rotate;
  -webkit-animation-duration: 2500ms;
  -ms-animation-duration: 2500ms;
  animation-duration: 2500ms;
  /*-webkit-animation-iteration-count: infinite;*/
  /*-ms-animation-iteration-count: infinite;*/
  /*animation-iteration-count: infinite;*/
  -webkit-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-play-state: running;
  -ms-animation-play-state: running;
  animation-play-state: running;
}
.wly_avt1{
  -webkit-animation-name: shake-rotate1;
  -ms-animation-name: shake-rotate1;
  animation-name: shake-rotate1;
  -webkit-animation-duration: 1500ms;
  -ms-animation-duration: 1500ms;
  animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-play-state: running;
  -ms-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes shake-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  2% {
    -webkit-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  4% {
    -webkit-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  6% {
    -webkit-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  8% {
    -webkit-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  10% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  12% {
    -webkit-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  14% {
    -webkit-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  16% {
    -webkit-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  18% {
    -webkit-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  20% {
    -webkit-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  22% {
    -webkit-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  24% {
    -webkit-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  26% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  28% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  30% {
    -webkit-transform: translate(-50%, -50%) rotate(5.5deg);
  }
  32% {
    -webkit-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  34% {
    -webkit-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  36% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  38% {
    -webkit-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  40% {
    -webkit-transform: translate(-50%, -50%) rotate(4.5deg);
  }
  42% {
    -webkit-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  44% {
    -webkit-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  46% {
    -webkit-transform: translate(-50%, -50%) rotate(4.5deg);
  }
  48% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  52% {
    -webkit-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  54% {
    -webkit-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  56% {
    -webkit-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  58% {
    -webkit-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  60% {
    -webkit-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  62% {
    -webkit-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  64% {
    -webkit-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  66% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  68% {
    -webkit-transform: translate(-50%, -50%) rotate(2.5deg);
  }
  70% {
    -webkit-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  72% {
    -webkit-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  74% {
    -webkit-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  76% {
    -webkit-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  78% {
    -webkit-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  82% {
    -webkit-transform: translate(-50%, -50%) rotate(4.5deg);
  }
  84% {
    -webkit-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  86% {
    -webkit-transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  88% {
    -webkit-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  90% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  92% {
    -webkit-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  94% {
    -webkit-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  96% {
    -webkit-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  98% {
    -webkit-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
}

@-ms-keyframes shake-rotate {
  0% {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
  }
  2% {
    -ms-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  4% {
    -ms-transform: translate(-50%, -50%) rotate(5.5deg);
  }
  6% {
    -ms-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  8% {
    -ms-transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  10% {
    -ms-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  12% {
    -ms-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  14% {
    -ms-transform: translate(-50%, -50%) rotate(5.5deg);
  }
  16% {
    -ms-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  18% {
    -ms-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  20% {
    -ms-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  22% {
    -ms-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  24% {
    -ms-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  26% {
    -ms-transform: translate(-50%, -50%) rotate(4.5deg);
  }
  28% {
    -ms-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  30% {
    -ms-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  32% {
    -ms-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  34% {
    -ms-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  36% {
    -ms-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  38% {
    -ms-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  40% {
    -ms-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  42% {
    -ms-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  44% {
    -ms-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  46% {
    -ms-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  48% {
    -ms-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  50% {
    -ms-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  52% {
    -ms-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  54% {
    -ms-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  56% {
    -ms-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  58% {
    -ms-transform: translate(-50%, -50%) rotate(2.5deg);
  }
  60% {
    -ms-transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  62% {
    -ms-transform: translate(-50%, -50%) rotate(4.5deg);
  }
  64% {
    -ms-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  66% {
    -ms-transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  68% {
    -ms-transform: translate(-50%, -50%) rotate(0.5deg);
  }
  70% {
    -ms-transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  72% {
    -ms-transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  74% {
    -ms-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  76% {
    -ms-transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  78% {
    -ms-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  80% {
    -ms-transform: translate(-50%, -50%) rotate(3.5deg);
  }
  82% {
    -ms-transform: translate(-50%, -50%) rotate(1.5deg);
  }
  84% {
    -ms-transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  86% {
    -ms-transform: translate(-50%, -50%) rotate(2.5deg);
  }
  88% {
    -ms-transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  90% {
    -ms-transform: translate(-50%, -50%) rotate(2.5deg);
  }
  92% {
    -ms-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  94% {
    -ms-transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  96% {
    -ms-transform: translate(-50%, -50%) rotate(6.5deg);
  }
  98% {
    -ms-transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  100% {
    -ms-transform: translate(-50%, -50%) rotate(0deg)
  }
}

@keyframes shake-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  2% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  4% {
    transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  6% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  8% {
    transform: translate(-50%, -50%) rotate(4.5deg);
  }
  10% {
    transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  12% {
    transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  14% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  16% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  18% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  20% {
    transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  22% {
    transform: translate(-50%, -50%) rotate(6.5deg);
  }
  24% {
    transform: translate(-50%, -50%) rotate(0.5deg);
  }
  26% {
    transform: translate(-50%, -50%) rotate(-5.5deg);
  }
  28% {
    transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  32% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  34% {
    transform: translate(-50%, -50%) rotate(0.5deg);
  }
  36% {
    transform: translate(-50%, -50%) rotate(6.5deg);
  }
  38% {
    transform: translate(-50%, -50%) rotate(-6.5deg);
  }
  40% {
    transform: translate(-50%, -50%) rotate(3.5deg);
  }
  42% {
    transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  44% {
    transform: translate(-50%, -50%) rotate(5.5deg);
  }
  46% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  48% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(5.5deg);
  }
  52% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  54% {
    transform: translate(-50%, -50%) rotate(5.5deg);
  }
  56% {
    transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  58% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  60% {
    transform: translate(-50%, -50%) rotate(6.5deg);
  }
  62% {
    transform: translate(-50%, -50%) rotate(1.5deg);
  }
  64% {
    transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  66% {
    transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  68% {
    transform: translate(-50%, -50%) rotate(3.5deg);
  }
  70% {
    transform: translate(-50%, -50%) rotate(-4.5deg);
  }
  72% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  74% {
    transform: translate(-50%, -50%) rotate(2.5deg);
  }
  76% {
    transform: translate(-50%, -50%) rotate(-2.5deg);
  }
  78% {
    transform: translate(-50%, -50%) rotate(-0.5deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(-3.5deg);
  }
  82% {
    transform: translate(-50%, -50%) rotate(5.5deg);
  }
  84% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  86% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  88% {
    transform: translate(-50%, -50%) rotate(4.5deg);
  }
  90% {
    transform: translate(-50%, -50%) rotate(5.5deg);
  }
  92% {
    transform: translate(-50%, -50%) rotate(4.5deg);
  }
  94% {
    transform: translate(-50%, -50%) rotate(-1.5deg);
  }
  96% {
    transform: translate(-50%, -50%) rotate(3.5deg);
  }
  98% {
    transform: translate(-50%, -50%) rotate(-7.5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
@keyframes shake-rotate1{
  0%{
    transform: scale(1);
  }
  10%{
     transform:scale(1.04);
  }
  20%{
     transform:scale(1.08);
  }
  30%{
    transform:scale(1.12);
  }
  40%{
    transform:scale(1.16);
  }
  50%{
    transform:scale(1.2);
  }
  60%{
    transform:scale(1.16);
  }
  70%{
    transform:scale(1.12);
  }
  80%{
    transform:scale(1.08);
  }
  90%{
    transform:scale(1.04);
  }
  100%{
    transform:scale(1);
  }
}
.wly_counts_text_item div{
  font-family: '思源宋体';
}

.wly_counts_text_item_left {
  color: #ffbe9d;
  font-size: 0.17rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.wly_counts_text_item_time{
  margin-top: 0.1rem;
  margin-left: 0.2rem;
  color: #ffbe9d;
}
.wly_counts_text_item_left_a {
  color: #ffbe9d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  font-size: 0.16rem;
}
.wly_chai_box_img img {
  width: 100%;
}

.caidai_a img {
  animation: fadeInUp 1s;
}

.jz {
  pointer-events: none;
}
.wly_jl {
  top: 25px;
  font-size: 23px;
  width: 180px;
  left: -48px;
}
/*.wly_count {*/
/*  padding-bottom: 35px;*/
/*  padding-left: 86px;*/
/*  margin-top: 12px;*/
/*}*/
/*@media screen and (max-width: 2560px) {*/
/*  .wly_mh_th {*/
/*    width: 615px;*/
/*  }*/

/*  .wly_lw[data-v-179f6096] {*/
/*    width: 82px;*/
/*  }*/

/*  .wly_manghechai {*/
/*    width: 190px;*/
/*  }*/

/*  .wly_jl {*/
/*    top: 25px;*/
/*    font-size: 23px;*/
/*    width: 180px;*/
/*    left: -48px;*/
/*  }*/

/*  .wly_mh_item {*/
/*    height: 138px;*/
/*  }*/

/*  .wly_count {*/
/*    padding-bottom: 35px;*/
/*    padding-left: 86px;*/
/*    margin-top: 12px;*/
/*  }*/

/*  .wly_mh_list {*/
/*    padding: 40px 40px 0;*/
/*  }*/
/*}*/

/*@media screen and (max-width:1480px){*/
/*  .lihe_shang{*/
/*    width: 153px;*/
/*    top: -84px;*/
/*  }*/
/*  .lihe_xia{*/
/*    width: 230px;*/
/*    bottom: -51px;*/
/*  }*/
/*  .wly_mh_kkk{*/
/*    width: 209px;*/
/*    position: absolute;*/
/*    top: -36px;*/
/*    left: 185px;*/
/*  }*/
/*  .wly_mh_th {*/
/*    width:615px;*/
/*  }*/
/*  .wly_sycs {*/
/*    width: 260px;*/
/*  }*/
/*  .wly_count_box_count{*/
/*    left: 189px;*/
/*  }*/
/*  .wly_buttom img {*/
/*    width: 138px;*/
/*    margin-right: 15px;*/
/*  }*/
/*  .wly_lw {*/
/*    width: 88px;*/
/*  }*/
/*  .wly_chai{*/
/*    width:37px;*/
/*  }*/
/*  .wly_mh_item{*/
/*    height: 131px;*/
/*  }*/
/*  .wly_manghechai{*/
/*    width: 145px;*/
/*  }*/
/*  .wly_jl{*/
/*    width:141px;*/
/*    font-size: 20px;*/
/*    top:19px;*/
/*    transform: rotate(18deg) translate(15px,-20px);*/
/*  }*/
/*}*/
/*@media screen and (max-width:1290px){*/
/*  .wly_lw{*/
/*    width: 71px;*/
/*  }*/
/*  .wly_manghechai {*/
/*    width: 125px;*/
/*  }*/
/*  .wly_jl{*/
/*    width: 125px;*/
/*    font-size: 18px;*/
/*  }*/
/*  .wly_chai{*/
/*    width:29px;*/
/*  }*/
/*  .wly_mh_item{*/
/*    height: 114px;*/
/*  }*/
/*}*/

</style>
