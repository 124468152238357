<template>
<!--<div class="mask" @click="$emit('close',true)"> 暂时屏蔽注册窗口外点击关闭功能-->
<div class="mask">
  <div class="rcontent" v-loading="loading" @click.stop="test">
    <div class="rtitle">注册<img src="../assets/icon_guanbi.png" @click="$emit('close',true)" class="icon-close curpoint"></div>
   <div class="inputcon flexs" :class="{errstyle:judgeAttr('phone')}" ref="minput">
     <div class="pre_input">+86</div>
     <input  placeholder="请输入注册手机号" v-model="phone"  class="input"/>
   </div>
    <div class="inputslider flexs" style="padding: 0">
      <drag-verify
        :width="width"
        :height="height"
        :text="text"
        :circle="false"
        :success-text="successText"
        :background="background"
        :progress-bar-bg="progressBarBg"
        :completed-bg="completedBg"
        :handler-bg="handlerBg"
        :handler-icon="handlerIcon"
        :text-size="textSize"
        :success-icon="successIcon"
        ref="accountVerify"
      >
      </drag-verify>
    </div>
    <div class="inputcon flexs" :class="{errstyle:judgeAttr('inCode')}">
      <input placeholder="请输入短信验证码" v-model="inCode" class="input"/>
      <div class="hqcode flexcenter curpoint" @click="getCode">{{count?count+'S':'获取验证码'}}</div>
    </div>
    <div class="inputcon flexs" :class="{errstyle:judgeAttr('userNick')}">
      <input placeholder="请输入昵称" :maxlength="10" v-model="userNick" class="input"/>
    </div>
    <div class="inputcon flexs" :class="{errstyle:judgeAttr('password')}">
      <input placeholder="请输入6位以上密码" type="password" v-model="password" class="input"/>
    </div>
    <div class="inputcon flexs" :class="{errstyle:judgeAttr('invitationPhone')}">
      <input  placeholder="请输入邀请人手机号（非必填）" v-model="invitationPhone"  class="input"/>
    </div>
    <div class="errtip colorred" v-if="errs.length>0">{{rules[errs[0]]}}</div>
    <div class="flexs xieyi" >
      <i class="iconfont icon-fangxingxuanzhong" @click="agree=false" v-if="agree" style="font-size: 0.15rem;color: #1386F0"></i>
      <i class="iconfont icon-fangxingweixuanzhong" @click="agree=true" v-else style="font-size: 0.15rem;color: #dcdcdc"></i>
      <div style="margin-left:0.07rem;">同意
        <a class="colorblue curpoint" style="margin-left: 0.03rem"  target="_blank"
           href="http://www.abcodeedu.com/agreement/ABCodeAgreement.html">《ABCode用户协议》</a>
      </div>
    </div>
    <div class="tijiaobtn flexcenter curpoint" @click="submit">立即注册</div>
    <div class="flexbetween" style="margin-top:0.3rem">
      <div class="sline"></div>
      <div style="color: #ADADAD;font-size: 0.13rem">返回账号登录</div>
      <div class="sline"></div>
    </div>
    <div class="flexcenter" style="margin-top:0.2rem">
      <div style="text-align: center;cursor: pointer" @click="$emit('openLogin',true)">
        <img class="logintu" src="../assets/icon_shouji.png">
        <div class="login-tip">手机号登录</div>
      </div>
<!--      <div style="text-align: center" @click="openWechat">-->
<!--        <img class="logintu" src="../assets/wechat.png">-->
<!--        <div class="login-tip">微信登录</div>-->
<!--      </div>-->
    </div>
  </div>
</div>
</template>

<script>
 import {postRequest,postFormRequest} from '@/utils/common.js'
 import { setToken } from '@/utils/auth'
 import dragVerify from "vue-drag-verify";
export default {
  name: "register",
  data(){
    return {
      handlerIcon: "iconfont icon-shoushimima",
      successIcon: "iconfont icon-icon_right",
      background: "#F7F7F7",
      progressBarBg: "#1386F0",
      completedBg: "#1386F0",
      handlerBg: "#fff",
      text: "请将滑块拖动到右侧",
      successText: "验证成功",
      width: 0,
      height: 38,
      textSize: "0.16rem",
      activeName:'account',
      loading:false,
      inCode:'',
      password:'',
      phone:'',
      invitationPhone:'',
      userNick:'',
      agree:false,
      count:0,
      timers:null,
      rules:{
        phone:'手机号码格式不正确',
        inCode:'请输入短信验证码',
        userNick:'请输入昵称',
        password:'请输入6位以上密码',
        invitationPhone:'邀请人手机号码格式不正确',
      },
      errs:[]
    }
  },
  beforeDestroy() {
    if(this.timers){
      clearInterval(this.timers)
      this.timers=null
    }
  },
  mounted() {
    this.width=this.$refs.minput.clientWidth
  },
  components: { dragVerify },
  methods:{
    judgeAttr(attr){
      return this.errs.includes(attr)
    },
    test(){},
    openWechat(){
      window.location.href='http://www.abcodeedu.com:8001/abcode/unneed/wx/getWeiXinCode'
    },
    async submitForm(){
      let { code,data,message } = await postRequest(`abcode/unneed/userLogin/${this.phone}/${this.password}`,{
        inPassword:this.password,
        phone:this.phone
      })
      if(code == 20000){
        setToken(data.token)
        this.$message.success('登录成功')
        this.$store.commit('setAuthon',true)
        this.$store.commit('setShowLogin',false)
        location.reload();
      }else{
        this.$message.error(message || '操作错误')
      }
    },
    async submit(){
      this.errs=[]
      if(!this.phone || !(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phone))){
       this.errs.push('phone')
      }
      if(!this.inCode){
        this.errs.push('inCode')
      }
      if(!this.userNick){
        this.errs.push('userNick')
        return
      }
      if(!this.password){
        this.errs.push('password')
        return
      }
      if(this.invitationPhone && !(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.invitationPhone))){
        this.errs.push('invitationPhone')
      }
      if(this.errs.length>0)return
      if(!this.agree){
        this.$message.warning('请同意用户协议')
        return
      }
      this.loading=true
      let { code,message } = await postFormRequest('abcode/unneed/register',{
        phone:this.phone,
        inCode:this.inCode,
        userNick:this.userNick,
        password:this.password,
        invitationPhone:this.invitationPhone
      })
      this.loading=false
      if(code == 20000){
        this.$emit('close',true)
        this.$message.success(message || '操作成功')
        this.submitForm()
      }
    },
    async getCode(){
      if(this.count)return
      this.errs=[]
      if(!this.phone || !(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phone))){
        this.errs.push('phone')
        return
      }
      if(!this.$refs.accountVerify.isPassing ){
        this.$message.warning('请拖动滑块验证')
        return
      }
      let { code,message } = await postRequest(`abcode/unneed/sendAliYunPhoneCode/${this.phone}/1`,{})
      if(code == 20000){
        this.count=120
        this.timers=setInterval(()=>{
          if(this.count == 0){
            clearInterval(this.timers)
          }else{
            this.count--
          }
        },1000)
        this.$message.success(message || '操作错误')
      }
    }
  }
};
</script>

<style scoped>
.errstyle{
  width:3.68rem;border:0.01rem solid #ee312c !important;
}
.logintu{
  width: 0.48rem;
  height: 0.48rem;
}
.errtip{
  font-size: 0.14rem;
}
.login-tip{
  color: #DCDCDC;
  margin-top: 0.1rem;
  text-align: center;
}
.hqcode{
  width: 1rem;
  height: 0.45rem;
  position: absolute;
  right: 0;
  top:0;
  background: #1386F0;
  border-radius:0 0.06rem 0.06rem 0;
  color: white;
  font-size: 0.16rem;
}
.sline{
  width: 1.05rem;
  height: 0.01rem;
  background: #DCDCDC;
}
.xieyi{
  margin-top:0.3rem;
  font-size: 0.13rem;
  color: #808080;
}
.rcontent{
  width: 4rem;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  background: white;
  border-radius: 0.1rem;
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
}
.rtitle{
  color: #1386F0;
  font-size: 0.24rem;
  font-weight: bold;
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 0.3rem;
}
.icon-close{
  width: 0.16rem;
  height: 0.16rem;
  position: absolute;
  right: -0.26rem;
  top: -0.16rem;
}
.inputcon{
  width: 3.7rem;
  height: 0.45rem;
  background: #f7f7f7;
  border: 0.01rem solid #dcdcdc;
  border-radius: 0.06rem;
  padding: 0 0.14rem;
  font-size: 0.16rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.inputslider{
  height: 0.45rem;
  padding: 0 0.14rem;
  font-size: 0.16rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.inputcon input{
  border:none;
  width: 3rem;
  outline:none;
  background: #f7f7f7;
}
.pre_input{
  width: 0.7rem;
}
.tijiaobtn{
  /*width: 4.05rem;*/
  height: 0.46rem;
  background: #1386F0;
  border-radius: 0.23rem;
  color: white;
  font-size: 0.16rem;
margin-top:0.2rem
}
</style>
