export default {
  // 正式环境
  img_url:'http://image.abcodeedu.com/',
  baseurl:'http://www.abcodeedu.com:8002/'

  // 测试环境
  // img_url:'http://image.yuemaedu.com/',
  // baseurl:'http://www.yuemaedu.com:8001/'

}
