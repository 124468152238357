import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icons/iconfont.css'
import animated from 'animate.css'
import baseInfo from './config/index'
import { timeFormatter } from'@/utils/util'
import VueQuillEditor from 'vue-quill-editor'
import clipboard from 'clipboard';
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(animated)
Vue.prototype.imgurl=baseInfo.img_url;
Vue.prototype.timeFormatter=timeFormatter;
Vue.prototype.clipboard = clipboard;

Vue.prototype.$scrollTop=function(){
  document.body.scrollIntoView()
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
