import request from '@/utils/request'
import qs from 'qs'

// get请求
export function getRequest(url,query) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}




// post 请求
export function postRequest(url,data) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}
// post 请求
export function postFormRequest(url,data) {

  return request({
    url: url,
    method: 'post',
    transformRequest: [function (data) {
      return qs.stringify(data)
    }],
    headers:{'Content-Type':'application/x-www-form-urlencoded'},
    data: data
  })
}

// put请求
export function putRequest(url,data) {
  return request({
    url: url,
    method: 'put',
    data: data
  })
}

// put请求
export function putHeaderRequest(url,data) {
  return request({
    url: url,
    method: 'put',
    headers:{'Content-Type':'application/x-www-form-urlencoded'},
    data: data
  })
}

// delete请求
export function delRequest(url,params) {
  return request({
    url: url,
    method: 'delete',
    data:params || {}
  })
}
