<template>
  <div id="app">
    <div class="allhead-con" v-if="curRoute !='/agreement'" :class="{ topboxshaw: showbac }">
      <div class="allhead flexbetween">
        <img class="logo curpoint" :src="logourl" @click="$router.push('/')"/>
        <div class="flexs" style="width: 7.5rem">
          <div
            v-for="(item, index) in navs"
            :key="'nav_' + index"
            :class="{
              snavd: $route.path == item.path,
              snav: $route.path != item.path,
            }"
            :style="{ color: $route.path == item.path ? '#1386F0' : '#1A1A1A' }"
          >
            <div
              @click="$router.push(item.path)"
              style="font-size: 0.18rem"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="flexs">
          <el-dropdown @command="handleCommand"  v-if="isAuth">
            <div class="flexs" style="cursor: pointer">
              <img v-if="userInfo.photo" style="width: 0.34rem;height: 0.34rem;border-radius: 50%" :src="userInfo.photo"/>
              <img v-else style="width: 0.34rem;height: 0.34rem" src="./assets/favicons.png"/>
              <div style="font-size: 0.18rem;margin-left:0.1rem;margin-right:0.1rem;max-width: 2.5rem">{{userInfo.userNick}}</div>
              <img class="levelimg" v-if="userInfo.level == 1" :src="v1">
              <img class="levelimg" v-if="userInfo.level == 2" :src="v2">
              <img class="levelimg" v-if="userInfo.level == 3" :src="v3">
              <img class="levelimg" v-if="userInfo.level == 4" :src="v4">
              <img class="levelimg" v-if="userInfo.level == 5" :src="v5">
              <img class="levelimg" v-if="userInfo.level == 6" :src="v6">
              <img class="levelimg" v-if="userInfo.level == 7" :src="v7">
              <img class="levelimg" v-if="userInfo.level == 8" :src="v8">
              <img class="levelimg" v-if="userInfo.level == 9" :src="v9">
            </div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="info">个人中心</el-dropdown-item>
              <el-dropdown-item command="exit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div v-else class="flexs loginregister" >
            <div class="loginr"  @click="$store.commit('setShowLogin', true)">
              登录
            </div>
            <div class="loginr" style="border-right: none" @click="showRegister = true">注册</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: auto">
      <keep-alive v-if="$route.meta && $route.meta.keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </div>

    <div class="bottom" v-if="curRoute !='/agreement'">
      <div class="spro">
        <div class="curpoint" @click="goUrl">约码科技官网</div>
        <div class="curpoint" @click="$router.push('/systemCourse')">体系课</div>
        <div class="curpoint" @click="$router.push('/actualCombat/1')">项目课</div>
        <div class="curpoint" @click="$router.push('/actualCombat/2')">提升课</div>
        <div class="curpoint" @click="$router.push('/brushQuestions')">刷题</div>
        <div class="curpoint" @click="$router.push('/consult')">咨询</div>
        <div class="curpoint" @click="$router.push('/questionAnswer')">问答</div>
        <div class="curpoint" @click="$router.push('/brushBo')">刷博</div>
<!--        <div class="curpoint" @click="$router.push('/download')">下载</div>-->
        <div class="curpoint" @click="$router.push('/activitys')">活动</div>
        <a class="curpoint" target="_blank" style="color: #FFFFFF;text-decoration:none"
           href="http://www.abcodeedu.com/agreement/ABCodeAgreement.html">用户协议</a>
      </div>
      <div class="bcenter">
        <div class="flexs">
          <div class="fleft">
            <div class="flexs">
              <i
                class="iconfont ficon icon-24gf-telephone2"
                style="font-size: 0.23rem; color: #d3d3d3"
              ></i>
              <div>电话：18310141089</div>
            </div>
            <div class="flexs" style="margin-top: 0.25rem">
              <i
                class="iconfont ficon icon-youxiang"
                style="font-size: 0.23rem; color: #d3d3d3"
              ></i>
              <div>邮箱：yuema2021@126.com</div>
            </div>
          </div>
          <div class="fleft">
            <div class="flexs">
              <i
                class="iconfont ficon icon-weizhi"
                style="font-size: 0.23rem; color: #d3d3d3"
              ></i>
              <div>公司地址：济南市历城区耀泽商业广场15层1515</div>
            </div>
            <div class="flexs" style="margin-top: 0.25rem">
              <i
                class="iconfont ficon icon-shijian"
                style="font-size: 0.23rem; color: #d3d3d3"
              ></i>
              <div>工作时间：周一至周五（09:00-18:00）</div>
            </div>
          </div>
        </div>
        <div class="cright flexbetween">
          <div>
            <div class="topname">联系客服</div>
            <div class="ewm">
              <img src="./assets/QR2.png">
            </div>
          </div>
          <div>
            <div class="topname">关注我们</div>
            <div class="ewm">
              <img src="./assets/QR1.png">
            </div>
          </div>
        </div>
      </div>
      <div class="bher">
        <p >Copyright 2021 ©约码科技 All Rights Reserved</p>
      </div>
      <div class="bher" style="padding-bottom: 0.3rem">
        <img  src="./assets/bc.png" alt="" style="margin-right: 0.07rem;width: 0.2rem"/>
        <p >
          <a style="color: #a3a3a3;text-decoration:none" target="_blank" href=" http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001358">鲁公网安备37011202001442号</a>
        </p><p style="margin-left: 0.2rem">
        <a style="color: #a3a3a3;text-decoration:none" target="_blank" href="https://beian.miit.gov.cn"> 鲁ICP备2021024593号-3</a></p>
      </div>
    </div>
    <register
      v-if="showRegister"
      @close="showRegister = false"
      @openWechat="
        showLogin = false;
        showLogin = true;
      "
      @openLogin="showRegister = false;$store.commit('setShowLogin', true)"
    ></register>
    <wechatlogin v-if="showWechat" @close="showWechat = false" ></wechatlogin>
    <common-login
      v-if="showLogin" @openRigster="showRegister=true"
      @close="$store.commit('setShowLogin', false);$store.commit('setShowLogin', false)"
    ></common-login>
    <suspension ref="suspension"></suspension>
    <div class="blindBoxBtn curpoint" @click="showBlindBox = true">
      <img class="kaimanghe" src="./assets/blindBox/kaimanghe.png">
      <img class="manghe animate__animated animate__heartBeat animate__repeat-3" src="./assets/blindBox/chaikaimang.png">
    </div>
    <blindBox
      v-if="showBlindBox"
      @close="showBlindBox = false"
    />
  </div>
</template>
<script>
import register from "@/components/register";
import wechatlogin from "@/components/wechatlogin";
import { WebsocketMixin } from "@/mixins/WebsocketMixin";
import { getToken, removeToken } from "@/utils/auth";
import commonLogin from "./components/commonLogin";
import { getRequest } from "@/utils/common.js";
import suspension from "./components/suspension";
import blindBox from "./components/blindBox";

var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
if (is_mobi) {
  window.location.href = "http://m.abcodeedu.com/";
  // window.location.href = "http://image.abcodeedu.com/images/banner/loading.jpg";
}

export default {
  name: "app",
  mixins: [WebsocketMixin],
  components: { register, wechatlogin, commonLogin,suspension,blindBox },
  data() {
    return {
      v1:require('./assets/detail/v1.png'),
      v2:require('./assets/detail/v2.png'),
      v3:require('./assets/detail/v3.png'),
      v4:require('./assets/detail/v4.png'),
      v5:require('./assets/detail/v5.png'),
      v6:require('./assets/detail/v6.png'),
      v7:require('./assets/detail/v7.png'),
      v8:require('./assets/detail/v8.png'),
      v9:require('./assets/detail/v9.png'),
      showRegister: false,
      showBlindBox: false,
      logourl: require("./assets/logo.png"),
      showWechat: false,
      navs: [
        { path: "/", name: "首页" },
        { path: "/systemCourse", name: "体系课" },
        { path: "/actualCombat/1", name: "项目课" },
        { path: "/actualCombat/2", name: "提升课" },
        { path: "/brushQuestions", name: "刷题" },
        { path: "/consult", name: "咨询" },
        { path: "/questionAnswer", name: "问答" },
        { path: "/brushBo", name: "刷博" },
        // { path: "/download", name: "下载" },
        { path: "/activitys", name: "活动" },
      ],
      showbac: false,
      userInfo:{},
      curRoute:'/agreement',
      isMobile:/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scroll);
    // this.initWebSocket();
    // this.isAuth=getToken()

    this.curRoute=this.$route.path
    this.$store.commit("setAuthon", getToken() ? true : false);
  },

  watch: {
   '$route'(to){
     this.curRoute=to.path
   },
    isAuth(val){
      if(val){
        this.getUserInfo()
      }
    },
    refreshUserInfo(val){
      if(val){
        this.getUserInfo()
      }
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.isAuth;
    },
    showLogin() {
      return this.$store.state.showLogin;
    },
    refreshUserInfo(){
      return this.$store.state.refreshUserInfo;
    }
  },
  methods: {
    goUrl(){
      window.location.href='http://www.ityuema.com/'
    },
    async getUserInfo(){
      let {code,data} = await getRequest('abcode/user/queryUserInfo',{})
      if(code == 20000){
        this.userInfo=data || {}
      }else{
        this.$store.commit('setAuthon',false)
      }

      this.$store.commit('setRefreshUserInfo',false)
    },
    scroll() {
      this.showbac = window.pageYOffset > 50;
      this.$refs.suspension.showTop=this.showbac

    },
    handleCommand(command) {
      if (command == "register") {
        this.showRegister = true;
      } else if (command == "login") {
        this.showWechat = true;
      } else if (command == "info") {
        this.$router.push("/info");
      } else if (command == "exit") {
        removeToken();
        this.$store.commit("setAuthon", false);
        this.$router.push("/");
      } else {
        this.$router.push("/actualCombat?type=" + command);
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 !important;
  overflow-x: hidden;
}
.curpoint{
  cursor: pointer;
}
.defaultpoint{
  cursor: default;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

* {
  font-size: 0.14rem;
  text-align: left;
}

input {
  outline: none;
}
.colorgreen{
  color: #01aa60 !important;
}
.viewcontainer {
  width: 100%;
  overflow-y: scroll !important;
  height: 100%;
}

body {
  padding: 0 !important;
  margin: 0 !important;
}

#nav {
  padding: 0.3rem;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexbetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexs {
  display: flex;
  align-items: center;
}

.headezw {
  width: 100%;
  height: 0.8rem;
}

.colorblue {
  color: #1386f0 !important;
}

.colorred {
  color: #ee312c !important;
}

.fontweight {
  font-weight: bold;
}

.fleft {
  margin-right: 0.88rem;
  color: #d3d3d3;
}

.bottom {
  width: 100%;
  background: #424242;
}

.bher {
  width: 100%;
  height: 0.32rem;
  //background: #262626;
  color: #a3a3a3;
  font-size: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spro {
  color: #fff;
  font-size: 0.18rem;
  display: flex;
  align-items: center;
  padding-top: 0.66rem;
  width: 12rem;
  margin: auto;
  line-height: 0.4rem;
}

.spro div {
  margin-right: 0.3rem;
  cursor: pointer;
}

.bcenter {
  width: 12rem;
  margin: auto;
  margin-top: 0.08rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cright {
  width: 2.5rem;
  font-size: 0.18rem;
}
.ewm{
  width: 1.12rem;
  height: 1.12rem;
  border-radius: 0.1rem;
  //background: white;
}
.ewm img{
  width: 1.12rem;
  height: 1.12rem;
  border-radius: 0.1rem;

}

.topname {
  width: 1.12rem;
  text-align: center;
  margin-bottom: 0.09rem;
  color: white;
  margin-right: 0.09rem;
}

.ficon {
  width: 0.26rem;
  height: 0.21rem;
  margin-right: 0.08rem;
}

.mask {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;
  width: 100%;
  height: 100%;
  display: block;
}
</style>
<style scoped>
.levelimg{
  width: 0.25rem !important;
}
.loginregister{
  background: #f2f4f9;border-radius: 0.2rem;
  padding: 0.1rem 0;
}
.allhead-con {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  padding: 0.2rem 0;
  background: #fff;
}

.loginr {
  display: block;
  cursor: pointer;
  width: 0.7rem;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 0.16rem;
  color: #57585b;
  border-right: 0.01rem solid #c3c3c7;
}

.loginr:hover {
  color: #1386f0;
}

.topboxshaw {
  -moz-box-shadow: 0 0.04rem 0.15rem rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0.04rem 0.15rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.04rem 0.15rem rgba(0, 0, 0, 0.2);
}

.allhead {
  width: 12rem;
  margin: auto;
}

.logo {
  width: 1.5rem;
  /*height: 0.4rem;*/
}

.snav {
  padding: 0.05rem 0.06rem;
  border-radius: 0.06rem;
  color: #fff;
  font-size: 0.18rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.snav :hover {
  color: #1386f0;
}

.snavd {
  padding: 0.05rem 0.06rem;
  border-radius: 0.06rem;
  font-size: 0.18rem;
  margin-right: 0.1rem;
  background: #ffffff;
  color: #1386f0;
  cursor: pointer;
}

.hinputcon input {
  border: none;
  background: #f5f5f5;
}
</style>
<style>
.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon{
  text-align: center;
}
.expandAll, .shrinkAll {
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.expandAll {
  display: block;
  height: auto;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  -moz-transition: height 1s ease-in-out;
  -o-transition: height 1s ease-in-out;
  -webkit-transition: height 1s ease-in-out;
  transition: height 1s ease-in-out;
}
.shrinkAll {
  height:0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.4s ease-out;
  -moz-animation: hideAnimation 0.4s ease-out;
  -webkit-animation: hideAnimation 0.4s ease-out;
  -moz-transition: height 0.6s ease-out;
  -o-transition: height 0.6s ease-out;
  -webkit-transition: height 0.6s ease-out;
  transition: height 0.6s ease-out;
}

@keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }



  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }


  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }


  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
.blindBoxBtn {
  width: 0.9rem;
  position: fixed;
  right: -0.1rem;
  top: 44%;
  z-index: 100;
  padding: 0 0.15rem 0.15rem 0.15rem;
  text-align: center;
}
.manghe{
  width: 0.9rem;
}
.manghe:hover {
  transform: scale(1.3);
}
.kaimanghe{
  width: 0.7rem ;
}

</style>
