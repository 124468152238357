import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token,{ expires: 1, path: '' })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getCookieInfo(key) {
  return Cookies.get(key)
}
export function setCookieInfo(key,val) {
  return Cookies.set(key,val)
}
