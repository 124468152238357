<template>
  <div>
<!--    <div class="mask" @click="$emit('close',true)">  暂时屏蔽登陆窗口外点击关闭功能-->
    <div class="mask">
      <div class="dialog" v-loading="loading" @click.stop="test">
        <div class="topclose">
          <img src="../assets/icon_guanbi.png" class="icon-close curpoint" @click.stop="$emit('close',true)">
        </div>
        <el-tabs v-model="activeName" >
          <el-tab-pane label="账号登录" name="account">
            <div class="inputcon flexs" :class="{errstyle:judgeAttr('phone')}" ref="minput">
              <input  placeholder="请输入手机号" v-model="ruleForm.phone"  class="input"/>
            </div>
            <div class="inputcon flexs" :class="{errstyle:judgeAttr('inPassword')}" >
              <input  placeholder="请输入密码" type="password" v-model="ruleForm.inPassword"  class="input"/>
            </div>
            <el-form v-if="activeName=='account'" :model="ruleForm" status-icon  ref="ruleForm" label-width="0px" class="demo-ruleForm">
              <el-form-item  prop="inPassword">
                <drag-verify
                  :width="width"
                  :height="height"
                  :text="text"
                  :circle="false"
                  :success-text="successText"
                  :background="background"
                  :progress-bar-bg="progressBarBg"
                  :completed-bg="completedBg"
                  :handler-bg="handlerBg"
                  :handler-icon="handlerIcon"
                  :text-size="textSize"
                  :success-icon="successIcon"
                  ref="accountVerify"
                >
                </drag-verify>
              </el-form-item>
              <div class="errtip colorred" v-if="errs.length>0">{{rules[errs[0]]}}</div>
              <div class="flexs xieyi" >
                <div style="margin-left:0.07rem;">登录即同意
                  <a class="colorblue curpoint" style="margin-left: 0.03rem"  target="_blank"
                     href="http://www.abcodeedu.com/agreement/ABCodeAgreement.html">《ABCode用户协议》</a>
                </div>
              </div>
              <el-form-item>
                <el-button round type="primary" style="width: 100%" @click="submitForm('ruleForm')">立即登录</el-button>
              </el-form-item>
            </el-form>
            <div class="flexbetween" style="margin-top:0.3rem" >
              <div class="sline"></div>
              <div style="color: #ADADAD;font-size: 0.13rem">切换账号注册</div>
              <div class="sline"></div>
            </div>
            <div class="flexcenter" style="margin-top:0.2rem" >
              <div class="scontent" @click="goRegister">
                <img class="logintu curpoint" src="../assets/icon_register.png">
                <div class="login-tip curpoint">新用户注册</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="验证码登录" name="code">
            <div class="inputcon flexs" :class="{errstyle:judgeAttr('phone')}" >
              <input  placeholder="请输入手机号" v-model="ruleForm.phone"  class="input"/>
            </div>
            <el-form v-if="activeName=='code'" :model="ruleForm" status-icon  ref="ruleForm" label-width="0px" class="demo-ruleForm">
              <el-form-item  prop="inPassword">
                <drag-verify
                  :width="width"
                  :height="height"
                  :text="text"
                  :circle="false"
                  :success-text="successText"
                  :background="background"
                  :progress-bar-bg="progressBarBg"
                  :completed-bg="completedBg"
                  :handler-bg="handlerBg"
                  :handler-icon="handlerIcon"
                  :text-size="textSize"
                  :success-icon="successIcon"
                  ref="codeVerify"
                >
                </drag-verify>
              </el-form-item>
              <div class="inputcon flexs" :class="{errstyle:judgeAttr('phone')}" ref="minput">
                <input  placeholder="请输入短信验证码" v-model="ruleForm.inCode"  class="input"/>
                <div class="hqcode flexcenter curpoint" @click="getCode">{{count?count+'S':'获取验证码'}}</div>
              </div>
<!--              <el-form-item  prop="inCode">-->
<!--                <div class="inputcon flexs" :class="{errstyle:judgeAttr('phone')}" ref="minput">-->
<!--                  <input  placeholder="请输入手机号" v-model="ruleForm.phone"  class="input"/>-->
<!--                </div>-->
<!--                <el-input type="text" v-model="ruleForm.inCode" :class="{errstyle:judgeAttr('inCode')}"-->
<!--                          placeholder="请输入短信验证码"  >-->
<!--                  <template slot="append">-->
<!--                    <div class="appendbtn">-->
<!--                      <el-button  type="primary" @click="getCode" >{{count?count+'S':'获取验证码'}}</el-button>-->
<!--                    </div>-->

<!--                  </template>-->
<!--                </el-input>-->
<!--              </el-form-item>-->
              <div class="errtip colorred" v-if="errs.length>0">{{rules[errs[0]]}}</div>
              <div class="flexs xieyi" >
                <div style="margin-left:0.07rem;">登录即同意
                  <a class="colorblue curpoint" style="margin-left: 0.03rem"  target="_blank"
                     href="http://www.abcodeedu.com/agreement/ABCodeAgreement.html">《ABCode用户协议》</a>
                </div>
              </div>
              <el-form-item>
                <el-button round type="primary" style="width: 100%" @click="submitForm2('ruleForm')">立即登录</el-button>
              </el-form-item>
            </el-form>
                    <div class="flexbetween" style="margin-top:0.3rem">
                      <div class="sline"></div>
                      <div style="color: #ADADAD;font-size: 0.13rem">切换账号注册</div>
                      <div class="sline"></div>
                    </div>
                    <div class="flexcenter" style="margin-top:0.2rem">
                      <div class="scontent" @click="goRegister">
                        <img class="logintu curpoint" src="../assets/icon_register.png">
                        <div class="login-tip curpoint">新用户注册</div>
                      </div>
                    </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

  </div>

</template>

<script>
import dragVerify from "vue-drag-verify";
import {postRequest} from '@/utils/common.js'
import { setToken } from '@/utils/auth'


export default {
  name: "commonLogin",
  components: { dragVerify },
  data(){
    return {
      agree:false,
      inputType:'password',
      handlerIcon: "iconfont icon-shoushimima",
      successIcon: "iconfont icon-icon_right",
      background: "#F7F7F7",
      progressBarBg: "#1386F0",
      completedBg: "#1386F0",
      handlerBg: "#fff",
      text: "请将滑块拖动到右侧",
      successText: "验证成功",
      width: 0,
      height: 38,
      textSize: "0.16rem",
      activeName:'account',
      ruleForm:{
        inPassword:'',
        phone:'',
        inCode:''
      },
      errs:[],
      rules:{
        phone:'手机号码格式不正确',
        inCode:'请输入验证码',
        inPassword:'请输入密码'
      },
      count:0,
      timers:null,
      loading:false
    }
  },
  mounted() {
    this.width=this.$refs.minput.clientWidth

  },
  watch:{
    activeName(val){
      if(this.$refs[val+'Verify'] != undefined){
        this.$refs[val+'Verify'].reset();
      }
      this.errs=[]
    }
  },
  methods:{
    judgeAttr(attr){
      return this.errs.includes(attr)
    },
    test(){},
    goRegister(){
      this.$emit('close',true)
      this.$store.commit('setShowLogin',false)
      this.$emit('openRigster',true)

    },
    async submitForm(){
      this.errs=[]
      if(!this.ruleForm.phone || !(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.ruleForm.phone))){
        this.errs.push('phone')
      }
      if(!this.ruleForm.inPassword){
        this.errs.push('inPassword')
      }
      if(this.errs.length > 0)return
      if(!this.$refs.accountVerify.isPassing ){
        this.$message.warning('请拖动滑块验证')
        return
      }
      this.loading=true
      let { code,data,message } = await postRequest(`abcode/unneed/userLogin/${this.ruleForm.phone}/${this.ruleForm.inPassword}`,{
        inPassword:this.ruleForm.inPassword,
        phone:this.ruleForm.phone
      })
      this.loading=false
      if(code == 20000){
        this.$emit('close',true)
        setToken(data.token)
        this.$message.success('登录成功')
        this.$store.commit('setAuthon',true)
        this.$store.commit('setShowLogin',false)
        location.reload();
      }else{
        this.$message.error(message || '操作错误')
      }
    },
    async submitForm2(){
      this.errs=[]
      if(!this.ruleForm.phone || !(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.ruleForm.phone))){
        this.errs.push('phone')
      }
      if(!this.ruleForm.inCode){
        this.errs.push('inCode')
      }
      if(this.errs.length > 0)return
      if(!this.$refs.codeVerify.isPassing){
        this.$message.warning('请拖动滑块验证')
        return
      }
      this.loading=true
      let { code,data } = await postRequest(`abcode/unneed/phoneCodeLogin/${this.ruleForm.phone}/${this.ruleForm.inCode}`,{
        phone: this.ruleForm.phone,
        inCode:this.ruleForm.inCode
      })
      this.loading=false
      if(code == 20000){
        this.$emit('close',true)
        this.$message.success('登录成功')
        this.$store.commit('setAuthon',true)
        this.$store.commit('setShowLogin',false)
        location.reload();
        setToken(data.token)
      }
    },
    openWechat(){
      // this.$emit('openWechat',true)
      window.location.href='http://www.abcodeedu.com:8001/abcode/unneed/wx/getWeiXinCode'
    },
    async getCode(){
      this.errs=[]
      if(this.count)return
      if(!this.ruleForm.phone){
        this.errs.push('phone')
        return
      }
      if(!this.$refs.codeVerify.isPassing){
        this.$message.warning('请拖动滑块')
        return
      }

      let { code,message } = await postRequest(`abcode/unneed/sendAliYunPhoneCode/${this.ruleForm.phone}/0`,{})
      if(code == 20000){
        this.count=120
        this.timers=setInterval(()=>{
          if(this.count == 0){
            clearInterval(this.timers)
          }else{
            this.count--
          }
        },1000)
        this.$message.success(message || '操作成功')
      }
    }
  }
};
</script>

<style scoped>
.hqcode{
  width: 1rem;
  height: 0.45rem;
  position: absolute;
  right: 0;
  top:0;
  background: #1386F0;
  border-radius:0 0.06rem 0.06rem 0;
  color: white;
  font-size: 0.16rem;
}
.inputcon{
  width: 3.7rem;
  height: 0.45rem;
  background: #f7f7f7;
  border: 0.01rem solid #dcdcdc;
  border-radius: 0.06rem;
  padding: 0 0.14rem;
  font-size: 0.16rem;
  margin-bottom: 0.14rem;
  position: relative;
}
.inputcon input{
  border:none;
  width: 3rem;
  outline:none;
  background: #f7f7f7;
}
.pre_input{
  width: 0.7rem;
}
.errstyle{
  width:calc(100% - 0.02rem);border:0.01rem solid #ee312c;
}
.mask{
  display: block;
}
.xieyi{
  margin:0.3rem 0;
  font-size: 0.13rem;
  color: #808080;
}
.suffixicon{
  position: absolute;
  right: 0.2rem;
  top:0;
  cursor: pointer;
}
.logintu{
  width: 0.48rem;
  height: 0.48rem;
}
.sline{
  width: calc(50% - 0.8rem);
  height: 0.01rem;
  background: #DCDCDC;
}
.login-tip{

  margin-top: 0.1rem;
  text-align: center;
}
.scontent{
  text-align: center;
  color: #DCDCDC;
}
.scontent:hover{
  color: rgb(173, 173, 173);
}
.dialog{
  width: 4rem;
  background: #FFFFFF;
  border-radius: 0.1rem;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  padding: 0.2rem 0.3rem;
  z-index: 102;
}
.topclose{
  width: 100%;
  height: 0.1rem;
  position: relative;
}
.icon-close{
  width: 0.16rem;
  height: 0.16rem;
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
<style >
.appendbtn .el-button--primary{
background: #409EFF!important;
  color: #fff !important;
}

.drag_verify .dv_text{
  padding-left: 0.6rem!important;
}
.drag_verify .dv_handler{
  text-align: center !important;
}
</style>
