<template>
  <div class="mask">
    <div class="rcontent">
      <div class="rtitle">微信登录<img src="../assets/icon_guanbi.png" class="icon-close" @click="$emit('close',true)"></div>

      <div class="wewm">
        <iframe width="100%" height="100%" src="http://www.abcodeedu.com:8001/abcode/unneed/wx/getWeiXinCode"></iframe>
      </div>
      <div class="ewm-tip flexcenter">扫描公众号注册登录</div>

      <div class="flexbetween" style="margin-top:0.3rem">
        <div class="sline"></div>
        <div style="color: #ADADAD;font-size: 0.13rem">其他登录方式</div>
        <div class="sline"></div>
      </div>
      <div class="flexcenter" style="margin-top:0.2rem">
        <div style="text-align: center">
          <img class="logintu" src="../assets/icon_shouji.png">
          <div class="login-tip">手机号登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";
export default {
  name: "wechatlogin",
  mounted() {
    // this.getCode()
  },
  methods:{
    async getCode(){
      let { data } = await getRequest("abcode/unneed/wx/getWeiXinCode", {});
      console.log(data,'ddddddddddddddddd')

    }
  }
};
</script>

<style scoped>
.logintu{
  width: 0.48rem;
  height: 0.48rem;
}
.login-tip{
  color: #DCDCDC;
  margin-top: 0.1rem;
  text-align: center;
}

.sline{
  width: 1.05rem;
  height: 0.01rem;
  background: #DCDCDC;
}

.rcontent{
  width: 4.06rem;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  background: white;
  border-radius: 0.1rem;
  padding: 0.3rem 0.4rem 0.2rem 0.4rem;
}
.rtitle{
  color: #1386F0;
  font-size: 0.24rem;
  font-weight: bold;
  width: 100%;
  position: relative;
  margin-bottom: 0.3rem;
}
.icon-close{
  width: 0.16rem;
  height: 0.16rem;
  position: absolute;
  right: -0.26rem;
  top: -0.16rem;
}
.wewm{
  width: 2.34rem;
  height: 2.34rem;
  background: #E2E2E2;
  margin: auto;
}
.ewm-tip{
  color: #ADADAD;
  margin-top: 0.2rem;
  margin-bottom: 0.68rem;
}

</style>
