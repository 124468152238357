import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuth:false,
    showLogin:false,
    refreshUserInfo:false
  },
  mutations: {
    setAuthon: (state, isAuth) =>{
      state.isAuth=isAuth
    },
    setShowLogin:(state, showLogin)=>{
      state.showLogin=showLogin
    },
    setRefreshUserInfo:(state,refreshUserInfo)=>{
      state.refreshUserInfo=refreshUserInfo
    }
  },
  actions: {},
  modules: {},
});
