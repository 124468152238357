<template>
  <div class="suspension">
    <div
      class="suspension-item curpoint"
      @mouseleave="showEwm = false"
      @mouseenter="showEwm = true"
    >
      <img :src="concat" style="width: 0.45rem; height: 0.45rem" />
      <div class="subtip" style="margin-top: 0.07rem">技术交流</div>
      <div class="concat-ewm" v-if="showEwm">
<!--        <img :src="concatEwm" />-->
        <img :src="qqEwm"/>
<!--        <span>QQ交流群</span>-->
        <img :src="wxEwm" />
<!--        <span>微信交流群</span>-->
      </div>
    </div>
    <div class="suspension-item curpoint" @click="dialogVisible = true">
      <img :src="feedback" />
      <div class="subtip">意见反馈</div>
    </div>
    <div class="suspension-item curpoint" @click="$scrollTop()" v-if="showTop">
      <img :src="gotop" />
      <div class="subtip">返回顶部</div>
    </div>
    <el-dialog
      title="意见反馈"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="40%"
      center
    >
      <el-form ref="form" :model="form" label-width="1rem">
        <el-form-item label="反馈类型">
          <el-select
            v-model="form.feedType"
            placeholder="请选择反馈类型"
            style="width: 100%"
            @change="form.feedContent=''"
          >
            <el-option
              :label="item.name"
              v-for="item in feedbackTypes"
              :key="'feed' + item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="反馈内容">
          <el-input type="textarea" :placeholder="'反馈格式：'+getTypeText()+'+反馈内容'"
                    :maxlength="300" :show-word-limit="true"
                    :rows="4" v-model="form.feedContent"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关 闭</el-button
        >
        <el-button type="primary" @click="feedQuestion">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { postFormRequest } from "@/utils/common";

export default {
  name: "suspension",
  data() {
    return {
      feedback: require("../assets/feedback.png"),
      gotop: require("../assets/gotop.png"),
      concat: require("../assets/favicons.png"),
      showTop: false,
      concatEwm: require("../assets/concat.png"),
      qqEwm: this.imgurl+"images/qrCode/qq.png",
      wxEwm: this.imgurl+"images/qrCode/wx.png",
      showEwm: false,
      dialogVisible: false,
      form: {
        feedType: 0,
        feedContent: "",
      },
      rules:{},
      feedbackTypes: [
        { id: 0, name: "课程反馈",placeholder:'课程名称'},
        { id: 1, name: "面试题反馈",placeholder:'面试题目' },
        { id: 2, name: "文章反馈",placeholder:'文章名称' },
        { id: 3, name: "网站反馈",placeholder:'网站链接' },
        { id: 4, name: "导师反馈" ,placeholder:'导师名称'},
        { id: 5, name: "资料反馈",placeholder:'资料名称' },
        { id: 6, name: "活动反馈" ,placeholder:'活动名称'},
        { id: 7, name: "其它" ,placeholder:'其它'},
      ],
    };
  },
  methods: {
    getTypeText(){
      let text = '';
      this.feedbackTypes.forEach(item=>{
        if(this.form.feedType == item.id){
          text=item.placeholder
        }
      })
      return text
    },
    async feedQuestion() {
      console.log(this.form.feedContent,'this.form.feedContentthis.form.feedContentthis.form.feedContent')
      if(!this.form.feedContent){
        this.$message.warning('请输入反馈内容')
        return
      }
      let { code } = await postFormRequest("/abcode/feedback/unneed/commitFeedBack", this.form);
     if(code == 20000){
       this.$message.success('提交反馈内容成功')
       this.dialogVisible=false
     }
    },
  },
};
</script>

<style scoped>
.suspension {
  width: 0.7rem;
  background: #ffffff;
  border-radius: 0.16rem 0 0 0.16rem;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  top: 60%;
  z-index: 100;
  padding: 0 0.15rem 0.15rem 0.15rem;
}
.concat-ewm {
  position: absolute;
  right: 0.9rem;
  top: 0rem;
  z-index: 101;
  background: #ffffff;
  height: 2.5rem;
  border-radius: 0.16rem;
  width: 1.3rem;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.concat-ewm img {
  margin: 0.12rem 0.12rem 0rem 0.12rem;
  width: 1.05rem !important;
  height: 1.05rem !important;
}
.suspension-item {
  text-align: center;
  padding: 0.2rem 0 0 0;
  position: relative;
}
.suspension-item:hover {
  color: #1386f0;
}
.suspension-item img {
  width: 0.3rem;
  height: 0.3rem;
}
.subtip {
  margin-top: 0.15rem;
  font-size: 0.16rem;
  width: 100%;
  text-align: center;
}
</style>
