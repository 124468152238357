<template>
  <div style="background: #ffffff; overflow-x: hidden">
    <div class="headezw"></div>
    <div class="header" ref="shead" >
      <el-carousel
        :interval="3000"
        height="7rem"
        :autoplay="true"
        arrow="never"
      >
        <el-carousel-item
          v-for="item in banners"
          :key="'banner' + item.imageId"
        >
          <img
            @click="goUrl(item)"
            :src="item.imagePath"
            :style="{ height: 7 + 'rem' }"
            style="width: 19.2rem"
            class="curpoint"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="centercon">
      <div class="maintent">
        <div class="maintitle">
          <span style="font-size: 0.36rem">在这里，</span
          ><span class="colorblue" style="font-size: 0.36rem">我能做什么？</span>
        </div>
        <div class="flexbetween" style="margin-top: 0.5rem">
          <div
            class="scenter1 flexs"
            v-for="(item, index) in service1"
            :key="'s1' + index"
            @mouseenter="curIndex = item.index"
            @mouseleave="curIndex = ''"
            @click="$router.push(item.path)"
          >
            <div
              class="flexs"
              style="width: 100%; height: 100%; padding: 0 0.25rem"
            >
              <img :src="item.img" />
              <div>
                <div class="ctitle1">{{ item.title }}</div>
                <div class="ctip1" v-html="item.tip"></div>
              </div>
            </div>

            <div
              class="scontent2 animate__animated"
              :class="{ rightIn: curIndex == item.index }"
            >
              <div class="scewm flexcenter">
                <div>
                  <!--                  <div class="ewmcon">{{ item.title }}</div>-->
                  <div
                    class="ewmtip"
                    v-for="(sitem, sindex) in item.fan"
                    :key="'fan_' + sindex"
                  >
                    {{ sitem }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flexbetween service2">
          <div
            class="scenter1"
            v-for="(item, index) in service2"
            :key="'s2' + index"
            @mouseenter="curIndex = item.index"
            @mouseleave="curIndex = ''"
            @click="$router.push(item.path)"
          >
            <div
              class="flexs"
              style="width: 100%; height: 100%; padding: 0 0.25rem"
            >
              <img :src="item.img" />
              <div>
                <div class="ctitle1">{{ item.title }}</div>
                <div class="ctip1" v-html="item.tip"></div>
              </div>
            </div>
            <div
              class="scontent2 animate__animated"
              :class="{ rightIn: curIndex == item.index }"
            >
              <div class="scewm flexcenter">
                <div>
<!--                  <div class="ewmcon">{{ item.title }}</div>-->
<!--                  <div class="ewmtip">{{ item.tip }}</div>-->
                  <div
                    class="ewmtip"
                    v-for="(sitem, sindex) in item.fan"
                    :key="'fan_' + sindex"
                  >
                    {{ sitem }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centercon">
      <div class="maintent">
        <div class="word">HIGHLIGHTS</div>
        <div class="maintitle">
          <span style="font-size: 0.36rem">平台</span
          ><span class="colorblue" style="font-size: 0.36rem">亮点</span>
        </div>
      </div>
      <div class="lingdian-con flexcenter" ref="curNum" >
        <div class="flexs" style="width:12rem"  @mouseleave="character=''">
          <div>
            <div class="lingdian-item curpoint" v-if="character == 1 || character == 5" >
              <div
                class="
                  liangdian-selected
                  animate__animated animate__rotateInUpRights
                "
              ></div>

              <div class="seleted-top animate__animated animate__wordshow">
                <div class="ldpm flexcenter" :class="{'bac_1_hover':character == 1,'bac_5_hover':character == 5}">
                  <div class="ldpm-title">
                    {{ character == 1 ? "体系" : "有问"
                    }}<span style="color: #ffffff; font-size: 0.36rem">{{
                      character == 1 ? "课程" : "有答"
                    }}</span>
                  </div>
                </div>
                <div class="ldmp-tip" v-if="character == 1">
                  <div>免费专人服务、有问题老师答</div>
                  <div>精典全面专业、闯关学更有趣</div>
                </div>
                <div class="ldmp-tip" v-else>
                  <div>提问解决难题、解答获取奖励</div>
                </div>
              </div>
              <img
                class="ldmp-book animate__animated animate__fadeshow"
                src="../assets/index/pic_tixikc.png"
              />
            </div>

            <div v-else>
              <div
                class="comup"
                style="border-right: none"
                @mouseenter="character = 1"
              >
                <div class="comnum flexcenter bac_1">
                  <div class="ldpm-title" style="color: #000">体系课程</div>
                </div>
                <div class="comtip">
                  <div>免费专人服务、有问题老师答</div>
                  <div>精典全面专业、闯关学更有趣</div>
                </div>
              </div>
              <div
                class="comdown"
                style="border-right: none"
                @mouseenter="character = 5"
              >
                <div class="comnum flexcenter bac_5">
                  <div class="ldpm-title" style="color: #000">有问有答</div>
                </div>
                <div class="comtip">
                  <div>提问解决难题、解答获取奖励</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="lingdian-item curpoint" v-if="character == 2 || character == 6">
              <div
                class="
                  liangdian-selected
                  animate__animated animate__rotateInUpRights
                "
              ></div>
              <div class="seleted-top animate__animated animate__wordshow">
                <div class="ldpm flexcenter" :class="{'bac_2_hover':character == 2,'bac_6_hover':character == 6}">
                  <div class="ldpm-title">
                    {{ character == 2 ? "实战" : "精彩"
                    }}<span style="color: #ffffff; font-size: 0.36rem">{{
                      character == 2 ? "项目" : "博文"
                    }}</span>
                  </div>
                </div>
                <div class="ldmp-tip" v-if="character == 2">
                  <div>企业真实项目、面试摸查能力</div>
                  <div>项目老师把控、增长实践能力</div>
                </div>
                <div class="ldmp-tip" v-else>
                  <div>发博记录所学、刷博增长知识</div>
                </div>
              </div>
              <img
                class="ldmp-book animate__animated animate__fadeshow"
                src="../assets/index/pic_tixikc.png"
              />
            </div>
            <div v-else>
              <div
                class="comup"
                style="border-right: none"
                @mouseenter="character = 2"
              >
                <div class="comnum flexcenter bac_2">
                  <div class="ldpm-title" style="color: #000">实战项目</div>
                </div>
                <div class="comtip">
                  <div>企业真实项目、面试摸查能力</div>
                  <div>项目老师把控、增长实践能力</div>
                </div>
              </div>
              <div
                class="comdown"
                style="border-right: none"
                @mouseenter="character = 6"
              >
                <div class="comnum flexcenter bac_6">
                  <div class="ldpm-title" style="color: #000">精彩博文</div>
                </div>
                <div class="comtip"><div>发博记录所学、刷博增长知识</div></div>
              </div>
            </div>
          </div>
          <div>
            <div class="lingdian-item curpoint" v-if="character == 7 || character == 3">
              <div
                class="
                  liangdian-selected
                  animate__animated animate__rotateInUpRights
                "
              ></div>
              <div class="seleted-top animate__animated animate__wordshow">
                <div class="ldpm flexcenter" :class="{'bac_3_hover':character == 3,'bac_7_hover':character == 7}">
                  <div class="ldpm-title">
                    {{ character == 3 ? "海量" : "资源"
                    }}<span style="color: #ffffff; font-size: 0.36rem">{{
                      character == 3 ? "题库" : "共享"
                    }}</span>
                  </div>
                </div>
                <div class="ldmp-tip" v-if="character == 3">
                  <div>真题一网打尽、自测掌握情况</div>
                  <div>解析准确精简、入职大厂无忧</div>
                </div>
                <div class="ldmp-tip" v-else>
                  <div>上传获取奖励、下载效率工作</div>
                </div>
              </div>
              <img
                class="ldmp-book animate__animated animate__fadeshow"
                src="../assets/index/pic_tixikc.png"
              />
            </div>
            <div v-else>
              <div
                class="comup"
                style="border-right: none"
                @mouseenter="character = 3"
              >
                <div class="comnum flexcenter bac_3">
                  <div class="ldpm-title" style="color: #000">海量题库</div>
                </div>
                <div class="comtip">
                  <div>自测掌握情况、真题一网打尽</div>
                  <div>解析准确精简、学习面试无忧</div>
                </div>
              </div>
              <div
                class="comdown"
                style="border-right: none"
                @mouseenter="character = 7"
              >
                <div class="comnum flexcenter bac_7">
                  <div class="ldpm-title" style="color: #000">资源共享</div>
                </div>
                <div class="comtip"><div>上传获取奖励、下载效率工作</div></div>
              </div>
            </div>
          </div>
          <div>
            <div class="lingdian-item curpoint" v-if="character == 4 || character == 8">
              <div
                class="
                  liangdian-selected
                  animate__animated animate__rotateInUpRights
                "
              ></div>
              <div class="seleted-top animate__animated animate__wordshow">
                <div class="ldpm flexcenter" :class="{'bac_4_hover':character == 4,'bac_8_hover':character == 8}">
                  <div class="ldpm-title">
                    {{ character == 4 ? "专家" : "活动"
                    }}<span style="color: #ffffff; font-size: 0.36rem">{{
                      character == 4 ? "咨询" : "沙龙"
                    }}</span>
                  </div>
                </div>
                <div class="ldmp-tip" v-if="character == 4">
                  <div>职场免费咨询、学习疑惑解答</div>
                  <div>专业职业规划、工作就业解忧</div>
                </div>
                <div class="ldmp-tip" v-else>
                  <div>线上免费开播、进阶技术分享</div>
                  <div>线下沙龙交流、接触同行大牛</div>
                </div>
              </div>
              <img
                class="ldmp-book animate__animated animate__fadeshow"
                src="../assets/index/pic_tixikc.png"
              />
            </div>
            <div v-else>
              <div
                class="comup"
                style="border-right-width: 0.02rem"
                @mouseenter="character = 4"
              >
                <div class="comnum flexcenter bac_4">
                  <div class="ldpm-title" style="color: #000">专家咨询</div>
                </div>
                <div class="comtip">
                  <div>职场免费咨询、学习疑惑解答</div>
                  <div>专业职业规划、工作就业解忧</div>
                </div>
              </div>
              <div
                class="comdown"
                style="border-right-width: 0.02rem"
                @mouseenter="character = 8"
              >
                <div class="comnum flexcenter bac_8">
                  <div class="ldpm-title" style="color: #000">活动沙龙</div>
                </div>
                <div class="comtip">
                  <div>线上免费开播、进阶技术分享</div>
                  <div>线下沙龙交流、接触同行大牛</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centercon">
      <div class="maintent">
        <div class="word">DATA PRESENTATION</div>
        <div class="maintitle">
          <span style="font-size: 0.36rem">数据</span
          ><span class="colorblue" style="font-size: 0.36rem">展示</span>
        </div>
        <div class="flexcenter" style="margin-top: 0.75rem">
          <div class="numcon flexbetween">
            <div v-for="(item, index) in nums" :key="'sindex' + index">
              <div class="flexcenter">
                <img class="sicon1" :src="item.img" />
              </div>
              <div class="sicontip">
                <countTo
                  v-if="showNum"
                  :startVal="0"
                  :endVal="parseInt(countInfo[item.attr])"
                  :duration="3000"
                ></countTo>
              </div>
              <div class="sicons flexcenter">
                <div class="sdot" :style="{ background: item.color }"></div>
                <div>{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bigcontent">
      <div class="word">EXCELLENT LECTURER</div>
      <div class="maintitle">
        <span style="font-size: 0.36rem">优秀</span
        ><span class="colorblue" style="font-size: 0.36rem">讲师</span>
      </div>

      <div class="carousel-con">
        <el-carousel :interval="3000" arrow="always" :autoplay="true" :height="'5.7rem'" >
          <el-carousel-item v-for="item in tlen" :key="'sinde' + item">
            <div style="width: 100%" class="flexcenter">
              <div class="sitems flexbetween">
                <div
                  class="sitem"
                  v-for="sitem in item == tlen
                    ? teachers.length - (item - 1) * 3
                    : 3"
                  :key="'child' + sitem"
                  @mouseenter="curTeacher=teachers[sitem - 1 + (item - 1) * 3].teacherId"
                  @mouseleave="curTeacher=''"
                >
                  <div style="width: 100%" class="flexcenter">
                    <div class="svatar flexcenter animate__animated" :class="{'animate__smallTeacher':teachers[sitem - 1 + (item - 1) * 3].teacherId==curTeacher}">
                      <img
                        :src="teachers[sitem - 1 + (item - 1) * 3].teacherPhoto"
                      />
                    </div>
                  </div>

                  <div class="sinfo">
                    <div class=" animate__animated"  :class="{'animate__fadeTeacher':teachers[sitem - 1 + (item - 1) * 3].teacherId==curTeacher}">
                      <div class="sname " >
                        {{ teachers[sitem - 1 + (item - 1) * 3].teacherName }}
                      </div>
                      <div class="role flexcenter " >
                      <span>{{
                          teachers[sitem - 1 + (item - 1) * 3].teacherPosition
                        }}</span>
                      </div>
                      <div style="margin-top:0.05rem" class="flexcenter " >
                        <span class="innertip" :style="{marginRight:sindex == (teachers[sitem - 1 + (item - 1) * 3].teacherLabels.length-1)?0:'0.1rem'}" v-for="(iitem,sindex) in teachers[sitem - 1 + (item - 1) * 3].teacherLabels" :key="'inner'+sindex">
                          {{iitem}}
                        </span>
                      </div>
                    </div>

                    <div class="daoshitip animate__animated" :class="{'animate__upTeacher':teachers[sitem - 1 + (item - 1) * 3].teacherId==curTeacher}">

                      <div class="flexcenter innertro" >
                       {{teachers[sitem - 1 + (item - 1) * 3].shortDesc}}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="centercon">
      <div class="maintent">
        <div class="word">ABOUT US</div>
        <div class="maintitle">
          <span style="font-size: 0.36rem">关于</span
          ><span class="colorblue" style="font-size: 0.36rem">我们</span>
        </div>
        <div class="flexcenter" style="margin-top: 0.75rem">
          <div>
            <div class="sicontent">
              ABCode，约码科技旗下IT职业学习交流平台
            </div>
            <div class="sicontent">
              以企业实用为标准、提高效率为原则，全方位、高质量为用户、企业服务

            </div>
            <div class="sicontent">
              约码（山东）网络科技有限公司（简称：约码科技），创立于2021年4月19日，位于美丽泉城—济南
            </div>
            <div class="sicontent">
              公司自创建以来一直努力为IT教育和发展做出贡献，向着成为IT行业一站式服务企业的目标不断前进
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="companys">
      <div class="word">PARTNER COMPANIES</div>
      <div class="maintitle">
        <span style="font-size: 0.36rem">合作</span
        ><span class="colorblue" style="font-size: 0.36rem">企业</span>
      </div>

      <vue-seamless-scroll
          v-if="showscroll"
          :data="listData"
          :class-option="classOption"
          class="warp"
      >
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in listData" :key="index">
            <img :src="item" style="height: 0.56rem"/>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";
import countTo from "vue-count-to";
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: "Home",
  data() {
    return {
      listData: [
        require('../assets/companys/gongshanglogo.png'),
        require('../assets/companys/yiqifengtianlogo.png'),
        require('../assets/companys/yongyoulogo.png'),
        require('../assets/companys/baomalogo.png'),
        require('../assets/companys/haixinlogo.png'),
        require('../assets/companys/nongyelogo.png'),
        require('../assets/companys/youhanglogo.png'),
        require('../assets/companys/zhaoshanglogo.png')],
      classOption: {
        limitMoveNum:3,
        direction: 2,
      },
      showscroll:false,
      curTeacher:'',
      tempInterval: undefined,
      showNum: false,
      theight: (window.screen.height / 3) * 2 - 50,
      service1: [
        {
          img: require("../assets/index/icon_fuwu_suexi.png"),
          title: "我要学习",
          tip: "精、典、全<br/>体系学习更扎实",
          index: 1,
          fan: [
            "系统全面课程资源学习，体系学更扎实",
            "视频、文档、源码配齐，无需东拼西凑",
            "学习疑问随时问及时答，有问题老师答",
          ],
          path:'/systemCourse'
        },
        {
          img: require("../assets/index/icon_fuwu_shizhan.png"),
          title: "我要实战",
          tip: "企业真实项目<br/>增强实践能力",
          index: 2,
          fan: [
            "企业真实项目配套整理，增强实践能力",
            "项目实战能力水平面试，定制专属项目",
            "项目开发难题实时反馈，专人服务解决",
            "配套项目经理协同指导，融入团队协作",
          ],
          path:'/actualCombat/1'
        },
        {
          img: require("../assets/index/icon_fuwu_wenda.png"),
          title: "我要问答",
          tip: "互帮互助<br/>让问题迎刃而解",
          index: 3,
          fan: [
            "工作学习难题随时提问，难题不是问题",
            "遇到问题帮助及时解答，获取互助奖励",
          ],
          path:'/questionAnswer'
        },
        {
          img: require("../assets/index/icon_fuwu_shuati.png"),
          title: "我要刷题",
          tip: "评估自身水平<br/>让面试变得简单",
          index: 4,
          fan: [
            "自测掌握情况，真题一网打尽",
            "解析准确精简，学习面试无忧"
          ],
          path:'/brushQuestions'
        },
      ],
      service2: [
        {
          img: require("../assets/index/icon_fuwu_shuabo.png"),
          title: "我要刷博",
          tip: "记录所做所学<br/>积累职业经验",
          index: 5,
          fan: ["工作学习发布博文，能力积累", "搜索查阅所需博文，解决难题"],
          path:'/brushBo'
        },
        {
          img: require("../assets/index/icon_fuwu_zixun.png"),
          title: "我要咨询",
          tip: "在你迷茫的时候<br/>指明道路",
          index: 6,
          fan: [
            "职业规划免费咨询，专业导师指点",
            "就业指导免费咨询，就业难题解决",
            "工作学习免费咨询，遇到问题无忧",
          ],
          path:'/consult'
        },
        {
          img: require("../assets/index/activity.png"),
          title: "我要进阶",
          tip: "线上线下交流<br/>快速提升",
          index: 7,
          fan: [
            "线上免费直播，分享进阶技术",
            "线下沙龙交流，接触同行大牛",
          ],
          path:'/activitys'
        },
      ],
      curIndex: "",
      nums: [
        {
          img: require("../assets/index/icon_shuju_zhuce.png"),
          title: "注册用户数",
          tip: "20001+",
          color: "#FF6363",
          attr: "userCount",
        },
        {
          img: require("../assets/index/icon_shuju_wenda.png"),
          title: "问答数",
          tip: "20001+",
          color: "#26D82E",
          attr: "questionCount",
        },
        {
          img: require("../assets/index/icon_shuju_wenzhang.png"),
          title: "文章数",
          tip: "20001+",
          color: "#1486F1",
          attr: "articleCount",
        },
        {
          img: require("../assets/index/icon_shuju_mst.png"),
          title: "面试题数",
          tip: "20001+",
          color: "#FE9834",
          attr: "questionLibraryCount",
        },
      ],
      character: 1,
      swiper: true,
      teachers: [],
      tlen: 0,
      banners: [],
      countInfo: {}
    };
  },
  components: { countTo,vueSeamlessScroll },
  destroyed() {
    clearInterval(this.tempInterval);
  },
  mounted() {
    document.addEventListener("scroll", this.scroll);
    this.getTeachers();
    this.getBanner();
    this.getCount();
  },
  methods: {
    testScroll(){
      var oDiv = document.getElementById('div1');
      var oUl = document.getElementById('ul1');
      var speed = -1;//初始化速度
      oUl.innerHTML += oUl.innerHTML;//图片内容*2-----参考图（2）
      var oLi = document.getElementsByTagName('li');
      let wW = document.documentElement.clientWidth;// 窗口宽度
      let rem = wW * 180 / 1920;
      oUl.style.width = oLi.length * rem + 'px';//设置ul的宽度使图片可以放下
      function move() {
        if (oUl.offsetLeft < -(oUl.offsetWidth / 2)) {//向左滚动，当靠左的图4移出边框时
          oUl.style.left = 0;
        }

        if (oUl.offsetLeft > 0) {//向右滚动，当靠右的图1移出边框时
          oUl.style.left = -(oUl.offsetWidth / 2) + 'px';
        }

        oUl.style.left = oUl.offsetLeft + speed + 'px';
      }

      this.tempInterval = setInterval(move, 10);//全局变量 ，保存返回的定时器
      let _this = this
      oDiv.addEventListener('mouseleave', function() {
        if(_this.tempInterval){
          clearInterval(_this.tempInterval)
        }
        _this.tempInterval = setInterval(move, 10);
      }, false);
      oDiv.addEventListener('mouseenter', function() {
        if(_this.tempInterval){
          clearInterval(_this.tempInterval);
          _this.tempInterval=undefined
        }
      }, false);
    },
    goUrl(obj){
      if(obj.imageUrl){
        // window.location.href=obj.imageUrl
        window.location.href=obj.imageUrl
      }
    },


    async getCount() {
      let { data } = await getRequest(
        "abcode/unneed/report/queryIndexReport",
        {}
      );
      this.countInfo = data && data.items ? data.items : {};
    },
    async getBanner() {
      let { data } = await getRequest(
        "abcode/images/unneed/queryIndexBanner",
        {}
      );
      this.banners = (data && data.items ? data.items : []).map((item) => {
        return {
          ...item,
          imagePath: item.imagePath
            ? item.imagePath.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
        };
      });
    },
    async getTeachers() {
      let { data } = await getRequest(
        `abcode/teacher/unneed/queryIndexTeacher`,
        {}
      );
      this.teachers = (data && data.items ? data.items : []).map((item) => {
        return {
          ...item,
          teacherPhoto: item.teacherPhoto
            ? item.teacherPhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          teacherLabels: item.teacherLabel ? item.teacherLabel.split(",") : []
        };
      });
      this.tlen = Math.ceil(this.teachers.length / 3);
    },
    scroll() {
      this.showNum = window.pageYOffset > (this.$refs.curNum?this.$refs.curNum.offsetTop:0) - 100;
      if(this.showNum){
        this.showscroll=true
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.warp {
  width:12rem;
  height: 0.56rem;
  margin: 0 auto;
  overflow: hidden;
  margin-top:0.5rem;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 1.8rem;
        height: 0.58rem;
        margin-right:0.1rem;
        text-align: center;
        img{
          height: 0.56rem;
          border-radius: 0.2rem;
        }
      }
    }
  }
}
</style>
<style scoped>
.lingdian-item {
  position: relative;
  width: 3rem;
  height: 5.04rem;
}
@-webkit-keyframes rotateInUpRights {
  0% {
    width: 0px;
    height: 0px;
  }
  to {
    width: 3rem;
    height: 5.04rem;
  }
}
@keyframes rotateInUpRights {
  0% {
    width: 0px;
    height: 0px;
  }
  to {
    width: 3rem;
    height: 5.04rem;
  }
}
.animate__rotateInUpRights {
  -webkit-animation-name: rotateInUpRights;
  animation-name: rotateInUpRights;
  animation-duration: 1s;
}
@-webkit-keyframes fadeTeacher {
  0% {
   opacity: 1;
  }


  to {
   opacity: 0;
  }
}
@keyframes fadeTeacher {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.animate__fadeTeacher {
  -webkit-animation-name: fadeTeacher;
  animation-name: fadeTeacher;
  animation-duration: 1s;
}
@-webkit-keyframes fadeshow {
  0% {
    width: 0px;
    height: 0px;
  }
  to {
    width: 2.586rem;
    height: 1.5rem;
  }
}
@keyframes fadeshow {
  0% {
    width: 0px;
    height: 0px;
  }
  to {
    width: 2.586rem;
    height: 1.5rem;
  }
}

.animate__fadeshow {
  -webkit-animation-name: fadeshow;
  animation-name: fadeshow;
  animation-duration: 1s;
}
@-webkit-keyframes wordshow {
  0% {
    width: 0px;
    height: 0px;
  }
  to {
    width: 3.44rem;
    height: 2rem;
  }
}
@keyframes wordshow {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate__wordshow {
  -webkit-animation-name: wordshow;
  animation-name: wordshow;
  animation-duration: 1s;
}
@-webkit-keyframes upTeacher {
  0%{
    margin-top:0.1rem
  }
  33% {
   margin-top:0.1rem
  }
  to {
    margin-top:-1rem
  }
}
@keyframes upTeacher {
  0%{
    margin-top:0.1rem
  }
  33% {
    margin-top:0.1rem
  }
  to {
    margin-top:-1rem
  }
}
.animate__upTeacher{
  -webkit-animation-name: upTeacher;
  animation-name: upTeacher;
  animation-duration: 1.5s;
}
@-webkit-keyframes smallTeacher {
  0%{
    transform: scale3d(1,1,1);
  }
  to {
    transform: scale3d(0.6,0.6,0.6);
  }
}
@keyframes smallTeacher {
  0%{
    transform: scale3d(1,1,1);
  }
  to {
    transform: scale3d(0.6,0.6,0.6);
  }
}
.animate__smallTeacher{
  -webkit-animation-name: smallTeacher;
  animation-name: smallTeacher;
  animation-duration: 1.5s;
}
@-webkit-keyframes fadehide {
  0% {
    width: 4rem;
    height: 5.04rem;
    opacity: 1;
  }
  to {
    width: 4rem;
    height: 5.04rem;
    opacity: 0;
  }
}
@keyframes fadehide {
  0% {
    width: 4rem;
    height: 5.04rem;
    opacity: 1;
  }
  to {
    width: 4rem;
    height: 5.04rem;
    opacity: 0;
  }
}
.animate__fadehide {
  -webkit-animation-name: fadehide;
  animation-name: fadehide;
  animation-duration: 5s;
}
.lingdian-con {
  width: 100%;
  background: #eff7ff;
  height: 5.04rem;
}
.liangdian-selected {
  width: 4rem;
  height: 5.04rem;
  background: -webkit-linear-gradient(bottom, hsl(201, 88%, 53%), hsl(200, 87%, 53%)) no-repeat;
  border-radius: 0.1rem;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 1;
}
.seleted-top {
  position: absolute;
  left: 0.34rem;
  top: 0.6rem;
  z-index: 2;
}
.ldpm {
  width: 1.44rem;
  height: 1.09rem;
  background-size: 100% 100%;
}
.ldpm-title {
  color: #ffd014;
  font-size: 0.36rem;
  font-weight: bold;
}
.ldmp-tip {
  font-size: 0.24rem;
  font-weight: bold;
  margin-top: 0.2rem;
  color: #ffffff;
  width: 4rem;
}

.ldmp-book {
  position: absolute;
  right: 0px;
  bottom: 0.34rem;
  height: 1.5rem;
  z-index: 2;
}
.comup {
  width: 3rem;
  position: relative;
  height: 2.5rem;
  border: 0.01rem solid rgba(20, 134, 241, 0.3);
  border-bottom: none;
  border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
  cursor: pointer;
}
.comnum {
  width: 1.57rem;
  height: 1.09rem;
  position: absolute;
  left: 0.4rem;
  top: 0.34rem;
  background-size: 100% 100%;
}
.bac_1 {
  background: url("../assets/index/01.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_2 {
  background: url("../assets/index/02.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_3 {
  background: url("../assets/index/03.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_4 {
  background: url("../assets/index/04.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_5 {
  background: url("../assets/index/05.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_6 {
  background: url("../assets/index/06.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_7 {
  background: url("../assets/index/07.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_8 {
  background: url("../assets/index/08.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_1_hover {
  background: url("../assets/index/01_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_2_hover {
  background: url("../assets/index/02_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_3_hover {
  background: url("../assets/index/03_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_4_hover {
  background: url("../assets/index/04_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_5_hover {
  background: url("../assets/index/05_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_6_hover {
  background: url("../assets/index/06_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_7_hover {
  background: url("../assets/index/07_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.bac_8_hover {
  background: url("../assets/index/08_hover.png") no-repeat center;
  background-size: 100% 100%;
}
.comtip {
  font-size: 0.18rem;
  position: absolute;
  left: 0.4rem;
  top: 1.55rem;
  color: #666;
  width: 2.5rem;
  line-height: 0.31rem;
}
.comdown {
  width: 3rem;
  position: relative;
  height: 2.5rem;
  border: 0.01rem solid rgba(20, 134, 241, 0.3);
  border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
  cursor: pointer;
}
.companys {
  width: 100%;
  margin-top: 0.7rem;
  background: url("../assets/index/companys.png") no-repeat center;
  background-size: 100% 100%;
  padding: 0.6rem 0 1rem 0;
}

.carousel-con {
  width: 12rem;
  margin: auto;
  margin-top: 0.2rem;
}

.company-con {
  width: 12rem;
  margin: auto;
  margin-top: 0.6rem;
}

.header {
  width: 19.2rem;
  margin-left: calc(50% - 9.6rem);
}
.bigcontent {
  width: 100%;
  margin-top: 0.7rem;
  background: url("../assets/index/daoshibg.png") no-repeat center;
  background-size: 100% 100%;
  padding: 0.6rem 0 1rem 0;
}
.sicon1 {
  width: 0.6rem;
  height: 0.53rem;
}
.sicontip {
  margin-top: 0.2rem;
  font-family: DIN;
  font-weight: bold;
  color: #1486f1;
  line-height: 0.31rem;
  text-align: center;
}
.sicontip span {
  font-size: 0.48rem;
}
.sicons {
  margin-top: 0.2rem;
  colo: #191919;
  font-size: 0.22rem;
  text-align: center;
}
.sicontent {
  font-size: 0.18rem;
  font-weight: bold;
  color: #4d4d4d;
  text-align: center;
  line-height: 0.3rem;
}
.sdot {
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.numcon {
  width: 12rem;
}

.word {
  color: #999;
  font-size: 0.13rem;
  width: 100%;
  text-align: center;
}
.ewmcon {
  font-size: 0.18rem;
  font-weight: bold;
  color: #ffffff;
  margin: auto;
  text-align: center;
}
.service2 {
  margin: auto;
  margin-top: 0.25rem;
  width: 8.9rem;
}
.ewmtip {
  color: #ffffff;
  font-size: 0.12rem;
  text-align: center;
  line-height: 0.26rem;
}
.scewm {
  width: 2.74rem;
  height: 1.52rem;
  background: -webkit-linear-gradient(bottom, hsl(201, 88%, 53%), hsl(199, 85%, 55%)) no-repeat;
  box-shadow: 0px 0.1rem 0.29rem 0px rgba(33, 22, 19, 0.08);
  border-radius: 0.2rem;
}
.scenter1 {
  width: 2.74rem;
  height: 1.52rem;
  background: #ffffff;
  box-shadow: 0px 0.1rem 0.29rem 0px rgba(33, 22, 19, 0.08);
  border-radius: 0.2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
@-webkit-keyframes rightIn {
  0% {
    left: 2.74rem;
    opacity: 1;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes rightIn {
  0% {
    left: 2.74rem;
    opacity: 1;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

.scontent2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #1486f1;
  border-radius: 0.2rem;
  position: absolute;
  opacity: 0;
}
.rightIn {
  -webkit-animation-name: rightIn;
  animation-name: rightIn;
  animation-duration: 1s;
}

@-webkit-keyframes scales {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}
@keyframes scales {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

@-webkit-keyframes afade {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes afade {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.afade {
  -webkit-animation-name: afade;
  animation-name: afade;
}
.scenter1 img {
  width: 0.74rem;
  height: 0.74rem;
  margin-right: 0.17rem;
}
.ctitle1 {
  color: #1a1a1a;
  font-size: 0.2rem;
  font-weight: bold;
  text-align: center;
}
.ctip1 {
  color: #999;
  margin-top: 0.14rem;
  font-size: 0.16rem;
  width: 1.5rem;
  text-align: center;
}
.centercon {
  width: 100%;
}
.maintent {
  width: 12rem;
  margin: auto;
  padding: 0.5rem 0;
}

.maintitle {
  width: 100%;
  text-align: center;
  margin-top: 0.1rem;
  font-size: 0.3rem;
  font-weight: bold;
}

.sitems {
  width: 10rem;
}
.sitem {
  width: 3.15rem;
  margin-top: 0.34rem;
  cursor: pointer;
  height: 5rem;
}
/*.sitem:hover {*/
/*  transform: scale3d(1.5, 1.5, 1.5);*/
/*}*/
.sinfo {
  width: 3.15rem;
  padding-bottom: 0.4rem;
  background: #ffffff;
  box-shadow: 0px 0.1rem 0.29rem 0px rgba(33, 22, 19, 0.08);
  border-radius: 1.31rem 1.31rem 0.3rem 0.3rem;
  margin-top: -0.68rem;
  height: 4.2rem;
}
.svatar {
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  /*background: #1486f1;*/
}
.svatar img {
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
}
.sname {
  color: #2e2e2e;
  font-size: 0.17rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-top: 0.85rem;

}
.role {
  display: flex;
  margin: auto;
  margin-top: 0.2rem;
}
.role span {
  font-size: 0.13rem;
  color: #808080;
  border-radius: 0.1rem;
  background: #f4f4f4;
  padding: 0.03rem 0.05rem;
}

.daoshitip {
  color: #808080;
  font-size: 0.16rem;
  margin-top: 0.2rem;

}
.innertro{
  padding:0 0.2rem;
  margin-top:0.1rem;
  overflow: hidden;
  line-height: 0.25rem;
}
.innertip{
  padding: 0.02rem 0.03rem;
  border-radius: 0.02rem;
  background: #f5f5f5;
  margin-right: 0.1rem;
  margin-top:0.1rem;
  color: #999;
}
</style>

