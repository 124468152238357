import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agreement.vue"),
  },
  // {
  //   path: "/myactivity",
  //   name: "myactivity",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/myactivity.vue"),
  // },
  {
    path: "/questionAnswer",
    name: "questionAnswer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/questionAnswer.vue"),
  },
  {
    path: "/teacherDetail",
    name: "teacherDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherDetail.vue"),
  },
  {
    path: "/combatDetail",
    name: "combatDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/combatDetail.vue"),
  },
  {
    path: "/courseComment",
    name: "courseComment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseComment.vue"),
  },
  {
    path: "/courseDetail",
    name: "courseDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseDetail.vue"),
  },
  {
    path: "/videoLearning",
    name: "videoLearning",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/videoLearning.vue"),
  },
  {
    path: "/answerAnalysis",
    name: "answerAnalysis",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/answerAnalysis.vue"),
  },
  {
    path: "/answerSummary",
    name: "answerSummary",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/answerSummary.vue"),
  },
  {
    path: "/info",
    name: "personCenter",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/personCenter.vue"),
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/test.vue"),
  // },
  // {
  //   path: "/mycourse",
  //   name: "mycourse",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mycourse.vue"),
  // },
  {
    path: "/answer",
    name: "answer",
    meta:{
      keepAlive:true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/answer.vue"),
  },
  {
    path: "/activitys",
    name: "activitys",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activitys.vue"),
  },
  {
    path: "/brushBo",
    name: "brushBo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/brushBo.vue"),
  },
  // {
  //   path: "/setAccount",
  //   name: "setAccount",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/setAccount.vue"),
  // },
  // {
  //   path: "/info",
  //   name: "personInfo",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/personInfo.vue"),
  // },
  {
    path: "/brushQuestions",
    name: "brushQuestions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/brushQuestions.vue"),
  },
  {
    path: "/actualCombat/:type",
    name: "actualCombat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/actualCombat.vue"),
  },
  {
    path: "/consult",
    name: "consult",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/consult.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/download.vue"),
  },
  {
    path: "/systemCourse",
    name: "systemCourse",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/systemCourse.vue"),
  },

  {
    path: "/activityDetail",
    name: "activityDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activityDetail.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0}
  }
});

export default router;
