import DdWebSocket from './DdWebSocket'


export const WebsocketMixin = {
    data () {
        return {
            refreshDataWS: null
        }
    },
    destroyed: function () { // 离开页面生命周期函数
        if (this.refreshDataWS) {
            this.refreshDataWS.close()
        }

    },
    methods: {
        initWebSocket () {
            const vm = this
            // vm.refreshDataWS = DdWebSocket.initQDWS('ws://119.3.170.171:48080/websocket/message/VehicleMonitoring')
            vm.refreshDataWS = DdWebSocket.initQDWS('ws://192.168.2.176:48080/websocket/message/VehicleMonitoring')

            DdWebSocket.registerListner(vm.refreshDataWS,
                {
                    open: vm.websocketOnopen,
                    error: vm.websocketOnerror,
                    message: vm.websocketOnmessage,
                    close: vm.websocketOnclose
                })
            return vm.refreshDataWS
        },
        websocketOnopen () {
            console.log('WebSocket连接成功')
        },
        websocketOnerror () {
            console.log('WebSocket连接发生错误')
        },
        websocketOnclose () {
            console.log('WebSocket关闭成功')

        },
        /**
         * websocket接收线路车辆的运行信息  根据返回的数据类型进行相对应的store状态变化
         * @param e string  将推送过来的信息分类放在state状态中
         */
        websocketOnmessage (e) {
            console.log(e,'接收数据')

        },
        websocketSend (text) {
            console.log(text,'发送')
            // 数据发送
            try {
                this.refreshDataWS.send(text)
            } catch (err) {
                console.log('send failed (' + err.code + ')')
            }
        },
        closeWebsocket () {
            if (this.refreshDataWS) {
                this.refreshDataWS.close()
            }
        },
    }

}
